import { Action, Module, VuexModule } from 'vuex-module-decorators';

import vuex from '@/store';

@Module({ namespaced: true })
export default class Init extends VuexModule {
  // --------------------------------- //
  // ------------ Getters ------------ //
  // --------------------------------- //

  // ...

  // --------------------------------- //
  // ------------ Actions ------------ //
  // --------------------------------- //

  @Action
  async preInit() {
    await vuex.dispatch('User/init');
  }

  // ----------------------------------- //
  // ------------ Mutations ------------ //
  // ----------------------------------- //

  // ...
}
