
import { Component, Prop, Vue } from 'vue-property-decorator';
import Utils from '@/helpers/utils';

@Component
export default class BaseButton extends Vue {
  @Prop(Object) to;
  @Prop(String) text;
  @Prop(String) icon;
  @Prop(String) color;
  @Prop(String) height;
  @Prop(String) width;
  @Prop(String) borderColor;
  @Prop(String) borderWidth;
  @Prop(Boolean) iconRight;
  @Prop(Boolean) flat;
  @Prop(Boolean) square;
  @Prop(Boolean) round;
  @Prop(Boolean) block;
  @Prop(Boolean) light;
  @Prop(Boolean) large;
  @Prop(Boolean) small;
  @Prop(Boolean) xLarge;
  @Prop(Boolean) xSmall;
  @Prop(Boolean) alignLeft;
  @Prop(Boolean) alignRight;

  get localStyles() {
    const styles = {
      color: `var(--vd-${this.light || this.flat ? 'grey' : 'white'})`,
    } as any;

    // Sizes
    if (this.block) styles.width = '100%';
    if (this.width) styles.width = Utils.String.cssPropFit(this.width);
    if (this.height) styles.height = Utils.String.cssPropFit(this.height);
    if (this.borderWidth) styles.borderWidth = Utils.String.cssPropFit(this.borderWidth);

    // Colors
    if (this.color) styles.backgroundColor = Utils.String.cssColorChain(this.color);
    if (this.color) styles.borderColor = Utils.String.cssColorChain(this.color);
    if (this.borderColor) styles.borderColor = Utils.String.cssColorChain(this.borderColor);
    if (this.flat) styles.backgroundColor = 'transparent';
    if (this.flat) styles.borderColor = 'transparent';

    return styles;
  }

  get localCntStyles() {
    const styles = {} as any;

    if (this.alignLeft) styles.textAlign = 'left';
    if (this.alignLeft) styles.justifyContent = 'left';
    if (this.alignRight) styles.textAlign = 'right';
    if (this.alignRight) styles.justifyContent = 'right';

    return styles;
  }
}
