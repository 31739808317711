
import { Component, Emit, Model, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import Utils from '@/helpers/utils';

@Component
export default class BaseModal extends Vue {
  @Model('change', { type: Boolean }) opened;
  @Prop(String) classWrapper;
  @Prop(String) classContent;
  @Prop(String) title;
  @Prop(String) width;
  @Prop(Boolean) permanent;

  @Ref() wrapper: HTMLDivElement;

  isOpened = false;

  get localStyles() {
    const styles = {} as any;

    // Sizes
    if (this.width) styles.width = Utils.String.cssPropFit(this.width);

    return styles;
  }

  @Watch('opened') onOpenedStateChange(after) {
    this.isOpened = after;
  }

  mounted() {
    document.body.insertBefore(this.wrapper, null);

    this.onOpenedStateChange(this.opened);
    this.$on('change', this.onOpenedStateChange);
  }

  beforeDestroy() {
    this.wrapper.remove();
    this.$off('change', this.onOpenedStateChange);
  }

  @Emit()
  close() {
    this.$emit('change', false);
  }
}
