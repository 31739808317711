
import { Component, Model, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseCheckbox extends Vue {
  // State
  @Model('input', { type: Boolean }) value;
  @Prop(Boolean) disabled;

  // Visual effects
  @Prop(String) label;
  @Prop(String) description;
  @Prop(Boolean) block;

  get localWrapperStyles() {
    const styles = {} as any;

    // Sizes
    if (this.block) styles.width = '100%';

    return styles;
  }
}
