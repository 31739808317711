import Vue from 'vue';
import Vuex from 'vuex';
import { namespace } from 'vuex-class';

Vue.use(Vuex);

class VuexStore {
  private static store = new Vuex.Store({
    strict: true,
  });

  static add(name, id?) {
    VuexStore.get().registerModule(id || name, require(`./modules/${name}`).default);
    return namespace(id || name);
  }

  static get() {
    return VuexStore.store;
  }
}

export default VuexStore.get();

// System modules
export const Vendors = VuexStore.add('Vendors');
export const Global = VuexStore.add('Global');
export const Init = VuexStore.add('Init');
export const User = VuexStore.add('User');
export const Stats = VuexStore.add('Stats');

// Entity modules
export const Operators = VuexStore.add('entities/Operators', 'Operators');
export const Villages = VuexStore.add('entities/Villages', 'Villages');
export const Privileges = VuexStore.add('entities/Privileges', 'Privileges');
export const RecordTypes = VuexStore.add('entities/RecordTypes', 'RecordTypes');
export const PaymentTypes = VuexStore.add('entities/PaymentTypes', 'PaymentTypes');
export const Transactions = VuexStore.add('entities/Transactions', 'Transactions');
export const Subscribers = VuexStore.add('entities/Subscribers', 'Subscribers');
export const UsersLegal = VuexStore.add('entities/UsersLegal', 'UsersLegal');
export const Firms = VuexStore.add('entities/Firms', 'Firms');
