import { Module } from 'vuex-module-decorators';
import BaseEntity from '@/store/modules/entities/_BaseEntity';

@Module({ namespaced: true })
export default class Privileges extends BaseEntity {
  // -------------------------------- //
  // ------------ Getters ----------- //
  // -------------------------------- //

  get $endpoints() {
    return {
      load: 'privileges',
      loadFields: 'privilege/fields',
      create: 'privilege/new',
      delete: 'privilege',
      edit: 'privilege',
    };
  }

  get assignedList() {
    return super.assignedList;
  }

  get pagesCount() {
    return super.pagesCount;
  }

  // -------------------------------- //
  // ------------ Actions ----------- //
  // -------------------------------- //

  // ...

  // ----------------------------------- //
  // ------------ Mutations ------------ //
  // ----------------------------------- //

  // ...
}
