/* eslint-disable max-classes-per-file */

import Config from '@/config';
import Utils from '@/helpers/utils';
import { ErrorDataTypes } from '@/store/modules/Global';

export enum Methods {
  GET = 'get',
  PUT = 'put',
  POST = 'post',
  HEAD = 'head',
  PATCH = 'patch',
  DELETE = 'delete'
}

export enum DataMethods {
  QUERY,
  OBJECT,
  FORMDATA
}

export interface APIResponse {
  context: APIManager;
  headers: Record<string, unknown>;
  code?: number;
  ok?: boolean;
  msg?: string | any;
}

export class APIRequest {
  private readonly context: APIManager;
  private readonly url: string;
  private readonly data = {};

  private headers = new Headers();
  private dataType: DataMethods;
  private method: Methods;

  private errorVisibility = true;
  private cors = true;

  constructor(url, data, context) {
    this.context = context;
    this.data = data;
    this.url = url.replace(/([^:]\/)\/+/g, '$1');
  }

  private throwError(msg, nativeThrow = true) {
    if (this.errorVisibility) {
      Utils.Document.throw({
        msg,
        type: ErrorDataTypes.FAILED,
      });

      if (nativeThrow) throw msg;
    }
  }

  public setCORS(flag: boolean): APIRequest {
    this.cors = flag;
    return this;
  }

  public setErrorVisibility(flag: boolean): APIRequest {
    this.errorVisibility = flag;
    return this;
  }

  public setMethod(method: Methods): APIRequest {
    this.method = method;
    return this;
  }

  public setDataType(type: DataMethods): APIRequest {
    this.dataType = type;

    switch (this.dataType) {
      case DataMethods.OBJECT:
      case DataMethods.QUERY:
        this.setHeader('Content-Type', 'application/json');
        break;

      case DataMethods.FORMDATA:
        this.removeHeader('Content-Type');
        break;
    }

    return this;
  }

  public setHeader(key: string, value: string): APIRequest {
    this.headers.set(key, value);
    return this;
  }

  public removeHeader(key: string): APIRequest {
    this.headers.delete(key);
    return this;
  }

  public setNoAuth(): APIRequest {
    this.removeHeader('Authorization');
    return this;
  }

  public setAuthByValue(value, type = 'Bearer'): APIRequest {
    if (value && type) this.setHeader('Authorization', `${type} ${value}`);
    return this;
  }

  public setAuthByStore(name = 'token', type?: string): APIRequest {
    this.setAuthByValue(localStorage.getItem(name), type);
    return this;
  }

  public execute(credentials: RequestCredentials = 'include'): Promise<APIResponse> {
    if (!this.dataType) return Promise.reject(new Error('Content-Type is not defined'));
    if (!this.method) return Promise.reject(new Error('Method is not defined'));
    if (!this.url) return Promise.reject(new Error('URL is not defined'));

    return new Promise((resolve, reject) => {
      const serializableMethods = [Methods.GET, Methods.DELETE, Methods.HEAD];
      let body: any;
      let query = '';

      if (serializableMethods.includes(this.method) || this.dataType === DataMethods.QUERY) {
        query += Utils.String.serialize(this.data);
      } else if (this.dataType === DataMethods.FORMDATA) {
        const formDataSerialize = (data, name = '') => {
          Object.keys(data).forEach((k) => {
            const key = `${name}${name ? '[' : ''}${k}${name ? ']' : ''}`;

            if (typeof this.data[k] === 'object') formDataSerialize(data[k], key);
            else body.append(key, data[k]);
          });
        };

        body = new FormData();
        formDataSerialize(this.data);
      } else if (this.dataType === DataMethods.OBJECT) {
        body = JSON.stringify(this.data);
      }

      const response: APIResponse = { context: this.context, headers: {} };
      fetch(this.url + query, {
        mode: this.cors ? 'cors' : 'no-cors',
        method: this.method.toUpperCase(),
        headers: this.headers,
        credentials,
        body,
      })
        .then((res) => {
          res.headers.forEach((value, key) => (response.headers[key] = value));
          response.ok = res.status === 200;
          response.code = res.status;

          return res.text();
        })
        .then((msg) => {
          try {
            response.msg = JSON.parse(msg);
          } catch (e) {
            response.msg = msg;
          }

          if (!response.ok) {
            this.throwError(response.msg?.error || 'Unknown error');
          }

          resolve(response);
        })
        .catch((error) => {
          this.throwError(error || 'Unknown error', false);
          reject(error);
        });
    });
  }
}

export class APIManager {
  private readonly apiURL = '';

  constructor(apiURL) {
    this.apiURL = apiURL;
  }

  public get(path?, data?) {
    return this.knock(path, data, Methods.GET);
  }

  public put(path?, data?) {
    return this.knock(path, data, Methods.PUT);
  }

  public post(path?, data?) {
    return this.knock(path, data, Methods.POST);
  }

  public head(path?, data?) {
    return this.knock(path, data, Methods.HEAD);
  }

  public patch(path?, data?) {
    return this.knock(path, data, Methods.PATCH);
  }

  public delete(path?, data?) {
    return this.knock(path, data, Methods.DELETE);
  }

  private knock(path = '', data: any = {}, method = Methods.POST): APIRequest {
    if (!this.apiURL) throw new Error('API_URL is not defined');

    const request = new APIRequest(`${this.apiURL}/${path}`, data, this);
    request.setAuthByStore();
    request.setMethod(method);
    request.setDataType(DataMethods.OBJECT);
    request.setHeader('Accept', 'application/json, text/plain, */*');
    return request;
  }
}

export default new APIManager(Config.URLS.API.CURRENT || '');
