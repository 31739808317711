function dateTime(mode = null){

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  try {
    let date = new Date(this);

    let formattedDate = [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('.');

    let formattedTime = [
      date.getHours(),
      padTo2Digits(date.getMinutes()),
      //padTo2Digits(date.getSeconds())
    ].join(':');

    if (mode == 'time') return formattedTime
    return `${formattedDate} ${formattedTime}`
  } catch (e) {
    console.error(e)
    return this
  }

}

export function prototypes(){

  String.prototype.parseUrl = function(){
    return this.replace(
      /(\b(?:https?|ftp):\/\/[\S]+)|(www.[\S]+)|(?:^|\s)(\S+\.[a-z]{2,})(?:\s|$)/gim,
      function(match, p1, p2, p3) {
        if (p1) {
          // URL already has a protocol, return as is
          return ' <a href="' + p1 + '" target="_blank">' + p1 + '</a> ';
        } else if (p2) {
          // URL starts with www, add https:// and return
          return ' <a href="https://' + p2 + '" target="_blank">' + p2 + '</a> ';
        } else if (p3) {
          // URL has no protocol or www, add https:// and return
          return ' <a href="https://' + p3 + '" target="_blank">' + p3 + '</a> ';
        }
      }
    );
  }

  String.prototype.toBlob = function(){
    const splitDataURI = this.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  String.prototype.toBase64 = function() {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL("image/png");
          //console.log(dataURL)
          resolve(dataURL)

      }
      img.src = this
    })
  }

  String.prototype.capitalize = function() {
    const lower = this.toLowerCase();
    return this.charAt(0).toUpperCase() + lower.slice(1);
  }

  Array.prototype.toggle = function(value){
    var index = this.indexOf(value);

    if (index === -1) {
      return this.push(value);
    } else {
      return this.splice(index, 1);
    }
  }

  Date.prototype.dmY = function() {

    function padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    }

    let result = [
      padTo2Digits(this.getDate()),
      padTo2Digits(this.getMonth() + 1),
      this.getFullYear(),
    ].join('.');

    return result
  }

  Date.prototype.Ymd = function() {

    function padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    }

    let result = [
      this.getFullYear(),
      padTo2Digits(this.getMonth() + 1),
      padTo2Digits(this.getDate()),
    ].join('-');

    return result
  }

  String.prototype.adjustTime = function(){

    let array = this.split(':')

    return `${array[0]}:${array[1]}`

  }

  String.prototype.fromYmdToDmY = function(){
    let date = this.split('-')
    return `${date[2]}.${date[1]}.${date[0]}`
  }

  String.prototype.dmY = function(){

    let date = new Date(this)

    function padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    }

    let result = [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('.');

    return result

  }

  String.prototype.Ymd = function(){

    let date = new Date(this)

    function padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    }

    let result = [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-');

    return result

  }

  String.prototype.dateTime = dateTime

  Number.prototype.dateTime = dateTime

  Date.prototype.dateTime = function(){
    const year = this.getFullYear();
    const month = (this.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const day = this.getDate().toString().padStart(2, '0');
    const hours = this.getHours().toString().padStart(2, '0');
    const minutes = this.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  Date.prototype.shiftMonth = function(delta){

    let newMonth = this.getMonth() + delta
    let newYear = this.getFullYear()
    newMonth = newMonth % 12
    return new Date(newYear, newMonth, 1)

  }

}

export default prototypes()
