
import { callbackify } from 'util';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseTabs extends Vue {
  @Prop({ type: Array, default: () => [] }) tabs;

  get currentTab() {
    return this.$route.params.tab;
  }

  getClasses({ to, selected }) {
    return {
      _active: selected || (this.currentTab && to.params?.tab === this.currentTab),
    };
  }

  onTabClick(tab) {
    if (tab.onclick){
      return tab.onclick(tab.id)
    }
    if (tab) {
      if (tab.params) {
        if (this.$route.params.tab !== tab.params.tab) this.$router.push(tab);
      } else {
        this.$router.push(tab);
      }
    }
  }
}
