
import { Component, Vue } from 'vue-property-decorator';

import Config from '@/config';
import Utils from '@/helpers/utils';
import { Global, Vendors } from '@/store';
import { routes } from '@/router/routes';

@Component({
  components: {
    TheHeader: () => import('@/components/common/TheHeader.vue'),
    TheSidebar: () => import('@/components/common/TheSidebar.vue'),
    TheErrorModal: () => import('@/components/common/TheErrorModal.vue'),
  },
})
export default class App extends Vue {
  @Vendors.State('libs') modules;
  @Vendors.Action('loadModule') loadModule;

  @Global.Getter('isIOS') isIOS;
  @Global.Getter('isChromium') isChromium;

  window = {
    width: 0,
    height: 0,
  };

  get theme() {
    return `app-theme-${Config.APPEARANCE.THEME}`;
  }

  get appClass() {
    return [
      `vd-app app-page-${this.$route.name.toLowerCase()}`,
      {
        [`app-page-${this.$route.meta?.class}`]: this.$route.meta?.class,
        [`app-page-group-${this.$route.meta?.group}`]: this.$route.meta?.group,
      },
    ];
  }

  get heldPages() {
    return routes
      .filter((route) => route.meta?.keep)
      .map((route) => route.component.split('/').reverse()[0])
      .filter((name, index, arr) => arr.indexOf(name) === index)
      .join(',');
  }

  created() {
    Utils.Console.timelapse('App/created');
  }

  mounted() {
    Utils.Console.timelapse('App/mounted');

    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleResize);
    this.handleResize();

    document.body.parentElement.classList.add(this.theme);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('orientationchange', this.handleResize);
  }

  handleResize() {
    this.window.height = window.innerHeight;
    if (this.isIOS) document.body.classList.add('is-ios');
    if (this.isChromium) document.body.classList.add('is-chromium');
    document.body.style.height = `${this.window.height}px`;
  }

  scroll() {
    window.dispatchEvent(new Event('scroll'));
  }
}
