import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Utils from '@/helpers/utils';

export enum States {
  PENDING,
  FAILED,
  LOADED
}

@Module({ namespaced: true })
export default class Vendors extends VuexModule {
  callbacks = new Map<string, Array<() => void>>();

  libs = {};
  states = {};
  styles = [];

  // -------------------------------- //
  // ------------ Actions ----------- //
  // -------------------------------- //

  /**
   * Load the script and save it in memory
   * @param loader Loader function
   * @param key Script ID
   */
  @Action
  async loadModule({ loader, key } = {} as any) {
    if (key && loader && Object.keys(this.libs).indexOf(key) === -1) {
      const timelapseStart = Date.now();

      Utils.Console.timelapseModule(`Loading "${key}"...`);
      this.context.commit('saveModule', { key });

      const vendor = await loader();
      const timeTook = Date.now() - timelapseStart;

      Utils.Console.timelapseModule(`Loaded "${key}" in`, timeTook, 'ms');
      this.context.commit('saveModule', { key, vendor });

      if (this.callbacks.has(key)) {
        this.callbacks.get(key).forEach((callback) => callback());
      }
    }
  }

  /**
   * Get the loaded script when ready
   * @param key Script ID
   */
  @Action getModule(key) {
    return new Promise((resolve) => {
      if (this.states[key] === States.LOADED) {
        resolve(this.libs[key]);
        return;
      }

      if (!this.callbacks.has(key)) this.callbacks.set(key, []);
      this.callbacks.get(key).push(() => resolve(this.libs[key]));
    });
  }

  // ----------------------------------- //
  // ------------ Mutations ------------ //
  // ----------------------------------- //

  /**
   * Save script to memory
   * @param key Script ID
   * @param vendor Script object
   */
  @Mutation saveModule({ key, vendor = null }) {
    this.libs[key] = vendor;
    this.states[key] = vendor ? States.LOADED : States.PENDING;
  }

  /**
   * Set state of css-file to loaded
   * @param path Path of css-file (aka ID)
   */
  @Mutation saveStyle({ path }) {
    this.styles.push(path);
  }
}
