import Vue from 'vue';

Vue.directive('click-outside', {
  bind(el, { def, value }, { context }) {
    def.onDocumentClick = ({ target }) => {
      if (!context.$vClickOutsideActive) return;

      const wrapperEl = value.wrapper?.() || context.$el;
      let isExistsIn = false;
      let parent = target;

      while (parent !== document.body) {
        if (parent === wrapperEl) {
          isExistsIn = true;
          break;
        }

        parent = parent.parentElement;
      }

      if (!isExistsIn && value.handler instanceof Function) value.handler();
    };

    def.onElementUpdated = (after) => {
      if (!after) {
        context.$vClickOutsideActive = false;
      } else {
        setTimeout(() => (context.$vClickOutsideActive = true));
      }
    };

    context.$on('update:click-outside', def.onElementUpdated);
    document.addEventListener('click', def.onDocumentClick);
  },

  unbind(el, { def }, { context }) {
    context.$off('update:click-outside', def.onElementUpdated);
    document.removeEventListener('click', def.onDocumentClick);
  },
} as any);
