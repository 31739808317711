export default class UConst {
  // TimePoints
  static readonly TP_SECOND = 1000;
  static readonly TP_MINUTE = 60000;
  static readonly TP_HOUR = 3600000;
  static readonly TP_DAY = 86400000;
  static readonly TP_WEEK = 604800000;
  static readonly TP_MONTH = 2592000000;
  static readonly TP_YEAR = 31536000000;

  // Check Mask for e-mail
  static readonly EMAIL = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
}
