/* eslint-disable */
import { load } from '@/plugins/i18n/helper';

export default {
  'testing-section': {
    'test': 'This is test',
    'longtext': load('en', 'test'),
  },

  'time-points': {
    'milliseconds': {
      '1': 'millisecond',
      '1-ago': 'millisecond',
      '2': 'milliseconds',
      '2-ago': 'milliseconds',
      '3': 'milliseconds',
      '3-ago': 'milliseconds',
    },
    'seconds': {
      '1': 'second',
      '1-ago': 'second',
      '2': 'seconds',
      '2-ago': 'seconds',
      '3': 'seconds',
      '3-ago': 'seconds',
    },
    'minutes': {
      '1': 'minute',
      '1-ago': 'minute',
      '2': 'minutes',
      '2-ago': 'minutes',
      '3': 'minutes',
      '3-ago': 'minutes',
    },
    'hours': {
      '1': 'hour',
      '1-ago': 'hour',
      '2': 'hours',
      '2-ago': 'hours',
      '3': 'hours',
      '3-ago': 'hours',
    },
    'days': {
      '1': 'day',
      '1-ago': 'day',
      '2': 'days',
      '2-ago': 'days',
      '3': 'days',
      '3-ago': 'days',
    },
    'weeks': {
      '1': 'week',
      '1-ago': 'week',
      '2': 'weeks',
      '2-ago': 'weeks',
      '3': 'weeks',
      '3-ago': 'weeks',
    },
    'months': {
      '1': 'month',
      '1-ago': 'month',
      '2': 'months',
      '2-ago': 'months',
      '3': 'months',
      '3-ago': 'months',
    },
    'years': {
      '1': 'year',
      '1-ago': 'year',
      '2': 'years',
      '2-ago': 'years',
      '3': 'years',
      '3-ago': 'years',
    },
  },
};
