/* eslint-disable max-classes-per-file */
/* eslint-disable quote-props */

import Utils from '@/helpers/utils';

export enum IconStatuses {
  CREATED,
  LOADING,
  FAILED,
  LOADED
}

export class Icon {
  status: IconStatuses;

  id: string;
  path: string;
  content: string;

  constructor(id, path) {
    this.status = IconStatuses.CREATED;
    this.id = id;
    this.path = path;
  }
}

export default class IconStore {
  private static readonly ICON_STORE_LINK = '/assets/icons/';
  private static readonly remoteIconPrefixes = ['mdi-'];

  private static icons: Map<string, Icon> = new Map();
  private static callbacks: Map<string, Array<() => void>> = new Map();

  /**
   * Registration icons
   * @param id Icon ID
   * @param path Path to icon
   * @param preload Should the icon be loaded immediately after registration
   */
  static async add(id: string, path: string, preload = false) {
    if (!id || id === 'undefined') return null;
    const icon = new Icon(id, path);

    IconStore.icons.set(id, icon);
    if (preload) await IconStore.load(id);

    return icon;
  }

  /**
   * Get html content of svg-icon
   * @param id Icon ID
   */
  static async get(id: string) {
    if (!id || id === 'undefined') return '';
    let icon = IconStore.icons.get(id);

    // If the icon is on an absolute path - instantly register it and continue loading
    if (IconStore.isRemote(id) && !icon) {
      Utils.Console.create()
        .addTags('APP', 'Icon')
        .end(`Icon is remote: ${id}`);

      icon = await IconStore.add(id, id);
    }

    if (!icon) {
      if (!IconStore.remoteIconPrefixes.filter((p) => id.startsWith(p)).length) {
        Utils.Console.create()
          .addTags('APP', 'Icon')
          .end(`Icon not registered: ${id}`);
      }

      return '';
    }

    if (icon.status !== IconStatuses.LOADED) {
      await IconStore.load(id);
    }

    return icon.content;
  }

  /**
   * Download the icon by http-request.
   * If an icon request has already been sent, the function waits for the end of that request,
   * after which it produces html-content in the place of the function call
   * @param id Идентификатор иконки
   */
  static async load(id: string) {
    if (!id || id === 'undefined') return;

    const icon = IconStore.icons.get(id);
    const isRemote = IconStore.isRemote(id);

    if (icon && icon.path) {
      // If the icon is registered and a request for its download has not yet been sent
      if (icon.status === IconStatuses.CREATED) {
        icon.status = IconStatuses.LOADING;

        const path = `${isRemote ? '' : IconStore.ICON_STORE_LINK}${icon.path}`;
        await fetch(path)
          .then((res) => {
            if ([200].indexOf(res.status) >= 0 && window.caches && window.caches.open) {
              window.caches.open('maxs-icons').then((cache) => {
                cache.put(path, res);
              });
            }

            return res.clone();
          })
          .then((res) => res.text())
          .then((content) => {
            icon.status = IconStatuses.LOADED;
            icon.content = content;

            // If there are functions awaiting the end of this request,
            // then we will notify them that the icon content has already loaded
            if (this.callbacks.has(id)) {
              this.callbacks.get(id).forEach((callback) => callback());
              this.callbacks.delete(id);
            }
          })
          .catch(() => {
            icon.status = IconStatuses.FAILED;
            Utils.Console.create()
              .addTags('APP', 'Icon')
              .end(`Icon not loaded: ${icon.path}`);
          });

        return;
      }

      // If the icon is registered and a request has already been sent to download it,
      // then wait for the end of that request
      await new Promise((resolve) => {
        if (!this.callbacks.has(id)) this.callbacks.set(id, []);
        this.callbacks.get(id).push(resolve);
      });

      return;
    }

    if (!IconStore.remoteIconPrefixes.filter((p) => id.startsWith(p)).length) {
      Utils.Console.create()
        .addTags('APP', 'Icon')
        .end(`Icon not registered: ${id}`);
    }
  }

  static async init() {
    await IconStore.add('test', 'home.svg');
  }

  /**
   * Absolute Path Check
   * @param id Icon ID
   * @return true if the icon is on an absolute path
   */
  private static isRemote(id: string) {
    return id.startsWith('http://') || id.startsWith('https://');
  }
}
