import { Action, Mutation, VuexModule } from 'vuex-module-decorators';
import Vue from 'vue';

import API from '@/helpers/API';
import Utils from '@/helpers/utils';

export default class BaseEntity extends VuexModule {
  $idList = [];

  list = [];
  fields = {};
  listTotalSize = 0;
  limit = 48;

  // -------------------------------- //
  // ------------ Getters ----------- //
  // -------------------------------- //

  get $endpoints() {
    return {
      load: '',
      loadFields: '',
      create: '',
      delete: '',
      edit: '',
    };
  }

  get assignedList() {
    return this.list;
  }

  get pagesCount() {
    return Math.ceil(this.listTotalSize / this.limit);
  }

  // -------------------------------- //
  // ------------ Helpers ----------- //
  // -------------------------------- //

  @Action
  $create(data) {
    if (!data) return null;

    const packet = { ...data, id: undefined };
    Object.keys(packet).forEach((key) => {
      if (!packet[key]) delete packet[key];
      if (packet[key] === true) packet[key] = 1;
    });

    return packet;
  }

  @Action
  $edit(data) {
    if (!data) return null;

    const packet = { ...data, id: undefined };
    Object.keys(packet).forEach((key) => {
      if (packet[key] === true) packet[key] = 1;
    });

    return packet;
  }

  // -------------------------------- //
  // ------------ Actions ----------- //
  // -------------------------------- //

  @Action
  async load({
    filters,
    additionalFilters,
    sortDesc = 'desc',
    sortField = 'created_at',
    page = 0,
  } = ({} as any)) {
    if (!this.$endpoints.load) {
      return Utils.Console.create()
        .addTags('APP', 'Vuex')
        .end('"load" endpoint is not defined');
    }

    if (!Object.keys(this.fields).length) await this.context.dispatch('loadFields');
    this.context.commit('flush');

    return API.post(this.$endpoints.load, {
      filters,
      additionalFilters,
      sortField,
      sortDesc,
      page,
      limit: this.limit,
    })
      .setErrorVisibility(false)
      .execute()
      .then((res) => {
        if (res.ok && res.msg.data) {
          this.context.commit('save', res.msg.data.list);
          this.context.commit('setListTotalSize', res.msg.data.count);
        }
      });
  }

  @Action
  loadFields() {
    if (!this.$endpoints.loadFields) {
      return Utils.Console.create()
        .addTags('APP', 'Vuex')
        .end('"loadFields" endpoint is not defined');
    }

    return API.get(this.$endpoints.loadFields)
      .setErrorVisibility(false)
      .execute()
      .then((res) => {
        if (res.ok && res.msg.data) {
          console.log('fields', res.msg.data)
          this.context.commit('saveFields', res.msg.data);
        }
      });
  }

  @Action
  async create(data) {
    if (!this.$endpoints.create) {
      return Utils.Console.create()
        .addTags('APP', 'Vuex')
        .end('"create" endpoint is not defined');
    }

    const packet = await this.context.dispatch('$create', data);
    if (!packet) return null;

    return API.post(this.$endpoints.create, packet)
      .execute()
      .then((res) => {
        if (res.ok) this.context.commit('prepend', res.msg.data);
        return res.msg.data;
      });
  }

  @Action
  async edit(data) {
    if (!this.$endpoints.edit) {
      return Utils.Console.create()
        .addTags('APP', 'Vuex')
        .end('"edit" endpoint is not defined');
    }

    if (!data || !data.id) {
      return Utils.Console.create()
        .addTags('APP', 'Vuex')
        .end('ID of editable item is not defined');
    }

    const packet = await this.context.dispatch('$edit', data);
    if (!packet) return null;

    return API.put(`${this.$endpoints.edit}/${data.id}`, packet)
      .execute()
      .then((res) => {
        if (res.ok) this.context.commit('update', res.msg.data);
        return res.msg.data;
      });
  }

  @Action
  delete(id) {
    if (!this.$endpoints.delete) {
      return Utils.Console.create()
        .addTags('APP', 'Vuex')
        .end('"delete" endpoint is not defined');
    }

    if (!id) {
      return Utils.Console.create()
        .addTags('APP', 'Vuex')
        .end('ID of deletable item is not defined');
    }

    return API.delete(`${this.$endpoints.delete}/${id}`)
      .execute()
      .then((res) => {
        if (res.ok && res.msg.data) this.context.commit('remove', id);
        return res.msg.data;
      });
  }

  // ----------------------------------- //
  // ------------ Mutations ------------ //
  // ----------------------------------- //

  @Mutation
  flush() {
    this.list = [];
    this.$idList = [];
  }

  @Mutation
  saveId(id) {
    if (this.$idList.includes(id)) return;
    this.$idList.push(id);
  }

  @Mutation
  save(data) {
    data.forEach((item) => {
      const { id } = item || {};
      if (!this.$idList.includes(id)) {
        this.list.push(item);
        if (id) this.$idList.push(id);
      }
    });
  }

  @Mutation
  saveFields(data) {
    this.fields = data;
  }

  @Mutation
  prepend(data) {
    const { id } = data || {};
    if (id && this.$idList.includes(id)) return;

    this.list.splice(0, 0, data);
    this.$idList.push(id);
  }

  @Mutation
  append(data) {
    const { id } = data || {};
    if (id && this.$idList.includes(id)) return;

    this.list.push(data);
    this.$idList.push(id);
  }

  @Mutation
  update(data) {
    const index = this.list.findIndex((item) => item.id === data.id);
    Vue.set(this.list, index, { ...this.list[index], ...data });
  }

  @Mutation
  remove(id) {
    const index = this.list.findIndex((item) => item.id === id);
    const idIndex = this.$idList.findIndex((itemId) => itemId === id);

    this.list.splice(index, 1);
    this.$idList.splice(idIndex, 1);
  }

  @Mutation
  setListTotalSize(listTotalSize) {
    this.listTotalSize = listTotalSize;
  }
}
