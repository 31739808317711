import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from '@/assets/locales/en';
import ru from '@/assets/locales/ru';
import ua from '@/assets/locales/ua';

Vue.use(VueI18n);

export default new VueI18n({
  locale: (window.navigator.language || '').slice(0, 2) || 'ua',
  silentTranslationWarn: true,
  fallbackLocale: 'ua',
  messages: { en, ru, ua },
});
