/* eslint-disable */
import { load } from '@/plugins/i18n/helper';

export default {
  'testing-section': {
    'test': 'Це тест',
    'longtext': load('ua', 'test'),
  },

  'time-points': {
    'milliseconds': {
      '1': 'мiлiсекунда',
      '1-ago': 'мiлiсекунду',
      '2': 'мiлiсекунди',
      '2-ago': 'мiлiсекунди',
      '3': 'мiлiсекунд',
      '3-ago': 'мiлiсекунд',
    },
    'seconds': {
      '1': 'секунда',
      '1-ago': 'секунду',
      '2': 'секунди',
      '2-ago': 'секунди',
      '3': 'секунд',
      '3-ago': 'секунд',
    },
    'minutes': {
      '1': 'хвилина',
      '1-ago': 'хвилину',
      '2': 'хвилини',
      '2-ago': 'хвилини',
      '3': 'хвилин',
      '3-ago': 'хвилин',
    },
    'hours': {
      '1': 'година',
      '1-ago': 'годину',
      '2': 'години',
      '2-ago': 'години',
      '3': 'годин',
      '3-ago': 'годин',
    },
    'days': {
      '1': 'день',
      '1-ago': 'день',
      '2': 'днi',
      '2-ago': 'днi',
      '3': 'днiв',
      '3-ago': 'днiв',
    },
    'weeks': {
      '1': 'тиждень',
      '1-ago': 'тиждень',
      '2': 'тижнi',
      '2-ago': 'тижнi',
      '3': 'тижнiв',
      '3-ago': 'тижнiв',
    },
    'months': {
      '1': 'мiсяць',
      '1-ago': 'мiсяць',
      '2': 'мiсяцi',
      '2-ago': 'мiсяцi',
      '3': 'мiсяцiв',
      '3-ago': 'мiсяцiв',
    },
    'years': {
      '1': 'рiк',
      '1-ago': 'рiк',
      '2': 'роки',
      '2-ago': 'роки',
      '3': 'рокiв',
      '3-ago': 'рокiв',
    },
  },
};
