
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    InfiniteLoading: () => import(/* webpackChunkName: "vue-infinite-loading" */ 'vue-infinite-loading'),
  },
})
export default class BaseAsyncList extends Vue {
  @Prop(Array) items;
  @Prop(Object) property;
  @Prop(String) noResults;
  @Prop({ type: Number, default: 100 }) delay;
  @Prop({ type: String, default: 'id' }) idKey;
  @Prop({ type: String, default: 'div' }) tag;

  itemsInChunk = 10;
  innerItems = [];
  renderedItems = [];
  infiniteId = Date.now();
  localProperty = null;

  @Watch('property', { deep: true }) onPropertyChange() {
    if (JSON.stringify(this.localProperty) !== JSON.stringify(this.property)) {
      this.setupRender();
      this.localProperty = this.property;
    }
  }

  @Watch('items', { deep: true }) onItemsChange() {
    this.updateRender();
  }

  mounted() {
    this.onPropertyChange();
    this.localProperty = this.property;
  }

  beforeDestroy() {
    this.innerItems = [];
    this.renderedItems = [];
  }

  infiniteHandler($state) {
    const toRenderItems = this.innerItems?.splice(0, this.itemsInChunk);
    if (toRenderItems.length) {
      this.renderedItems.push(...toRenderItems);
      setTimeout(() => $state.loaded(), 100);
    } else {
      $state.complete();
    }
  }

  setupRender() {
    this.innerItems = this.items?.slice();
    this.renderedItems = [];
    this.infiniteId += 1;
  }

  updateRender() {
    setTimeout(() => {
      if (!this.renderedItems.length || !this.items.length) {
        this.setupRender();
        return;
      }

      const maxItemsLength = this.renderedItems.length <= 3 ? this.renderedItems.length : 3;
      const isNeedRefresh = this.items
        .slice(0, maxItemsLength)
        .some((item) => {
          const findInRenderIndex = this.renderedItems
            .slice(0, maxItemsLength)
            .findIndex((renderItem) => (
              renderItem[this.idKey] === item[this.idKey]
            ));

          return findInRenderIndex < 0;
        });

      if (isNeedRefresh) this.setupRender();
    }, 300);
  }
}
