
import { Component, Emit, Model, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import Utils from '@/helpers/utils';

@Component
export default class BaseMenu extends Vue {
  @Model('change', { type: Boolean }) opened;
  @Prop(String) classWrapper;
  @Prop(String) width;
  @Prop(String) height;
  @Prop(Boolean) fillWidth;
  @Prop(Boolean) fillHeight;

  // Offsets
  @Prop(String) xOffset;
  @Prop(String) yOffset;

  // Centering by axis
  @Prop(Boolean) xCenter;
  @Prop(Boolean) yCenter;

  // Positioning from by x-axis
  @Prop({
    type: String,
    default: 'left',
    validator: (v: any) => ['left', 'right'].includes(v),
  }) xFrom;

  // Positioning from by y-axis
  @Prop({
    type: String,
    default: 'bottom',
    validator: (v: any) => ['top', 'bottom'].includes(v),
  }) yFrom;

  // Positioning to by x-axis
  @Prop({
    type: String,
    default: 'right',
    validator: (v: any) => ['left', 'right'].includes(v),
  }) xTo;

  // Positioning to by y-axis
  @Prop({
    type: String,
    default: 'bottom',
    validator: (v: any) => ['top', 'bottom'].includes(v),
  }) yTo;

  @Ref() wrapper: HTMLDivElement;
  @Ref() menu: HTMLDivElement;

  isOpened = false;
  pos = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  } as Record<string, any>;

  get localStyles() {
    const styles = {
      top: Utils.String.cssPropFit(this.pos.top || 'auto'),
      left: Utils.String.cssPropFit(this.pos.left || 'auto'),
      right: Utils.String.cssPropFit(this.pos.right || 'auto'),
      bottom: Utils.String.cssPropFit(this.pos.bottom || 'auto'),
    } as any;

    // Sizes
    styles.minWidth = Utils.String.cssPropFit(this.getWrapperWidth());
    styles.minHeight = Utils.String.cssPropFit(this.getWrapperHeight());

    return styles;
  }

  @Watch('opened') onOpenedStateChange(after) {
    this.isOpened = after;

    this.calculatePosition();
    this.$emit('update:click-outside', after);
  }

  mounted() {
    document.body.insertBefore(this.menu, null);
    window.addEventListener('scroll', this.calculatePosition);

    this.calculatePosition();
    this.onOpenedStateChange(this.opened);
    this.$on('change', this.onOpenedStateChange);
  }

  beforeDestroy() {
    this.$off('change', this.onOpenedStateChange);
    this.menu.remove();

    window.removeEventListener('scroll', this.calculatePosition);
  }

  @Emit()
  close() {
    this.$emit('change', false);
  }

  getWrapperWidth() {
    if (this.fillWidth && this.wrapper) return this.wrapper.getBoundingClientRect().width;
    if (this.width) return this.width;

    return 'auto';
  }

  getWrapperHeight() {
    if (this.fillHeight && this.wrapper) return this.wrapper.getBoundingClientRect().height;
    if (this.height) return this.height;

    return 'auto';
  }

  /**
   * Detect direction and position. Calculating css-coords.
   * {$side} - start (main) position
   * $side - end position
   * => / <= - visual direction
   */
  calculatePosition() {
    if (!this.isOpened || !this.wrapper || !this.menu) return;

    const rect = this.wrapper.getBoundingClientRect();
    const menuRect = this.menu.getBoundingClientRect();
    const pos = {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
    } as Record<string, any>;

    // X-axis
    if (this.xCenter) {
      pos.left = rect.left - ((menuRect.width - rect.width) / 2);

      // //////////////////////////////////////
      // If no centering, then calculate aligns
    } else if (this.xFrom === 'left') {
      if (this.xTo === 'left') {
        // left <= {left}
        pos.right = (window.innerWidth - rect.right) + rect.width;
      } else {
        // Default
        // {left} => right
        pos.left = rect.left;
      }
    } else if (this.xFrom === 'right') {
      if (this.xTo === 'left') {
        // left <= {right}
        pos.right = (window.innerWidth - rect.right);
      } else {
        // {right} => right
        pos.left = rect.left + rect.width;
      }
    }

    // Y-axis
    if (this.yCenter) {
      pos.top = rect.top - ((menuRect.height - rect.height) / 2);

      // //////////////////////////////////////
      // If no centering, then calculate aligns
    } else if (this.yFrom === 'top') {
      if (this.yTo === 'top') {
        // top <= {top}
        pos.bottom = (window.innerHeight - rect.bottom) + rect.height;
      } else {
        // {top} => bottom
        pos.top = rect.top;
      }
    } else if (this.yFrom === 'bottom') {
      if (this.yTo === 'top') {
        // top <= {bottom}
        pos.bottom = (window.innerHeight - rect.bottom);
      } else {
        // Default
        // {bottom} => bottom
        pos.top = rect.top + rect.height;
      }
    }

    // Applying X-offset
    const xOffset = parseInt(this.xOffset, 10);
    if (xOffset) {
      if (pos.left) pos.left += xOffset;
      if (pos.right) pos.right -= xOffset;
    }

    // Applying Y-offset
    const yOffset = parseInt(this.yOffset, 10);
    if (yOffset) {
      if (pos.top) pos.top += yOffset;
      if (pos.bottom) pos.bottom -= yOffset;
    }

    // Fixed out of window by y-axis
    if (pos.top + menuRect.height > window.innerHeight) {
      pos.top = 'auto';
      pos.bottom = '0px';
    }

    // Fixed out of window by x-axis
    if (pos.left + menuRect.width > window.innerWidth) {
      pos.left = 'auto';
      pos.right = '0px';
    }

    this.pos = pos;
  }
}
