
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FunctionalCalendar } from 'vue-functional-calendar';

@Component({
  components: {
    FunctionalCalendar,
  },
})
export default class BaseDatePicker extends Vue {
  @Prop() value;

  months = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
  ];

  monthsShort = [
    'Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь',
    'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек',
  ];

  days = [
    'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс',
  ];

  get date() {
    return {
      currentDate: this.value,
    };
  }
}
