
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Utils from '@/helpers/utils';
import IconStore from '@/helpers/IconStore';

@Component
export default class BaseIcon extends Vue {
  @Prop(String) name;
  @Prop(String) size;
  @Prop(String) color;
  @Prop(String) width;
  @Prop(String) height;
  @Prop(String) styles;
  @Prop(String) strokeWidth;
  @Prop(String) strokeColor;
  @Prop(Boolean) stroke;

  icon = null;

  /**
   * Get icon name
   */
  get iconName() {
    return (this.name || this.$slots.default?.[0]?.text || '').replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
  }

  /**
   * Get icon styles
   */
  get localStyles() {
    const styles = {} as any;

    // Sizes
    if (this.size) styles.fontSize = Utils.String.cssPropFit(this.size, 'em');
    if (this.width) styles.width = Utils.String.cssPropFit(this.width);
    if (this.width) styles.minWidth = Utils.String.cssPropFit(this.width);
    if (this.height) styles.height = Utils.String.cssPropFit(this.height);

    // Colors
    if (this.stroke) styles.strokeWidth = Utils.String.cssPropFit(this.strokeWidth || 1);
    if (this.color) styles.color = Utils.String.cssColorChain(this.color);
    if (this.color && this.stroke) styles.stroke = Utils.String.cssColorChain(this.color);
    if (this.color && !this.stroke) styles.fill = Utils.String.cssColorChain(this.color);

    // Custom stroke
    if (this.strokeWidth) styles.strokeWidth = Utils.String.cssPropFit(this.strokeWidth);
    if (this.strokeColor) styles.stroke = Utils.String.cssColorChain(this.strokeColor);

    return styles;
  }

  /**
   * Watch on changes of icon name and load icon
   */
  @Watch('name')
  async onNameChange() {
    const template = await IconStore.get(this.iconName);
    if (template) {
      this.icon = Vue.component('v-base-icon', { template });
    }
  }

  /**
   * Init icon
   */
  mounted() {
    this.onNameChange();
  }
}
