/* eslint-disable max-classes-per-file */

import Config from '@/config';

export interface GA {
  non_interaction?: boolean;
  event_category?: string;
  event_label?: string;
  value?: string;
}

export enum Colors {
  app = '#FCCC6B',
  timelapse = '#B455FF',
  module = '#FF6363',
  router = '#42B983',
  vuex = '#005F79',
  icon = '#43D9D8',
  gtag = '#FF7336',
  css = '#33A9DC'
}

export class UConsoleMessage {
  private tags: Array<{
    name: string;
    color: string;
  }> = [];

  /**
   * Add colored section like [NAME]
   * @param name Name of section
   * @param color Color of section
   */
  addTag(name: string, color?: string) {
    this.tags.push({
      name,
      color: color || Colors[name.toLowerCase()] || '#FFFFFF',
    });

    return this;
  }

  /**
   * Add several sections with predefined colors
   * @param tags
   */
  addTags(...tags) {
    tags.forEach((tag) => this.addTag(tag));
    return this;
  }

  /**
   * Build sections in one console.log-array
   */
  build() {
    const sections = [];
    const colors = [];

    this.tags.forEach((tag) => {
      sections.push(`%c[${tag.name}]`);
      colors.push(`color: ${tag.color};`);
    });

    sections.push('%c');
    colors.push('');

    return [`${sections.join('')}`].concat(colors);
  }

  /**
   * Finish building and out result to browser console
   * @param messages
   */
  end(...messages) {
    if (!Config.IS_DEV && !Config.IS_DEBUG) return;

    const prefix = this.build();
    // eslint-disable-next-line
    console.log(...prefix, ...messages);
  }
}

export default class UConsole {
  /**
   * Create console message with sections possibility
   */
  static create() {
    return new UConsoleMessage();
  }

  /**
   * Print out timelapse for app
   * @param args
   */
  public static timelapse(...args) {
    UConsole.create()
      .addTags('APP', 'Timelapse')
      .end(args[0], Date.now() - window.timelapseStart, ...args.slice(1));
  }

  /**
   * Print out timelapse for module
   * @param args
   */
  public static timelapseModule(...args) {
    UConsole.create()
      .addTags('APP', 'Timelapse', 'Module')
      .end(...args);
  }

  /**
   * Print out GoogleAnalytics event
   * @param name Name of event
   * @param data Sent data
   */
  public static ga(name, data: GA = {}) {
    // @ts-ignore
    if (window.gtag) {
      UConsole.create()
        .addTags('APP', 'GTAG')
        .end(`Sent "${name}"`, data);

      gtag('event', name, data);
    }
  }
}
