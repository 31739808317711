import { Module } from 'vuex-module-decorators';
import BaseEntity from '@/store/modules/entities/_BaseEntity';

@Module({ namespaced: true })
export default class UsersLegal extends BaseEntity {
  // -------------------------------- //
  // ------------ Getters ----------- //
  // -------------------------------- //

  get $endpoints() {
    return {
      load: 'users-legal',
      loadFields: 'user-legal/fields',
      create: 'user-legal/new',
      delete: 'user-legal',
      edit: 'user-legal',
    };
  }

  get assignedList() {
    return super.assignedList;
  }

  get pagesCount() {
    return super.pagesCount;
  }

  // -------------------------------- //
  // ------------ Actions ----------- //
  // -------------------------------- //

  // ...

  // ----------------------------------- //
  // ------------ Mutations ------------ //
  // ----------------------------------- //

  // ...
}
