import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import API from '@/helpers/API';

@Module({ namespaced: true })
export default class User extends VuexModule {
  isAuth = false;
  user = {
    id: null,
    role: null,
    email: null,
    login: null,
    username: null,
  };

  // -------------------------------- //
  // ------------ Getters ----------- //
  // -------------------------------- //

  // TODO

  // -------------------------------- //
  // ------------ Actions ----------- //
  // -------------------------------- //

  @Action init() {
    return API.post('operator/init')
      .setErrorVisibility(false)
      .execute()
      .then((res) => {
        if (res.ok && res.msg.data?.user) {
          this.context.commit('setAuth', res.msg.data.user);
          return true;
        }

        return false;
      })
      .catch(() => {
        localStorage.removeItem('token');
      });
  }

  @Action auth({ login, password } = {} as any) {
    return API.post('operator/auth', { login, password })
      .setErrorVisibility(false)
      .setNoAuth()
      .execute()
      .then((res) => {
        if (res.ok && res.msg.data?.user) {
          localStorage.setItem('token', res.msg.data.token);
          this.context.commit('setAuth', res.msg.data.user);
          return true;
        }

        return false;
      })
      .catch(() => {
        localStorage.removeItem('token');
      });
  }

  @Action signOut() {
    return API.post('operator/logout')
      .setErrorVisibility(false)
      .execute()
      .then((res) => {
        if (res.ok) {
          localStorage.removeItem('token');
          this.context.commit('setAuth', false);
        }
      })
      .catch(null);
  }

  // ----------------------------------- //
  // ------------ Mutations ------------ //
  // ----------------------------------- //

  /**
   * Set authorization state
   */
  @Mutation setAuth(data) {
    this.isAuth = !!data;

    if (!data) {
      window.location.href = '/';
    } else {
      this.user.id = data.id;
      this.user.role = data.role;
      this.user.email = data.email;
      this.user.login = data.login;
      this.user.username = data.username;
    }
  }
}
