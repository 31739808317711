export default class UImage {
  /**
   * Convert remote image to data-url
   * @param url Url of image
   * @param type Type of image
   */
  static remoteToDataUrl(url: string, type = 'image/jpeg'): Promise<string> {
    return new Promise((resolve) => {
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.getContext('2d').drawImage(img, 0, 0);
        resolve(canvas.toDataURL(type));
      };

      img.crossOrigin = 'anonymous';
      img.src = url;
    });
  }

  /**
   * Convert data-url of image to blob
   * @param dataUrl DataUrl of image
   */
  static dataUrlToBlob(dataUrl: string) {
    const binary = atob(dataUrl.split(',')[1]);
    const array = [];

    for (let i = 0; i < binary.length; i += 1) {
      array.push(binary.charCodeAt(i));
    }

    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
  }

  /**
   * Convert data-url of image to file
   * @param dataUrl DataUrl of image
   */
  static dataUrlToFile(dataUrl: string) {
    return new File([this.dataUrlToBlob(dataUrl)], `${Date.now()}.jpg`);
  }

  /**
   * Resize image
   * @param dataUrl DataUrl of image
   * @param ratio
   */
  static resize(dataUrl: string, ratio = 4 / 5): Promise<HTMLCanvasElement> {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        const { width, height } = image;

        if (width > height) {
          canvas.width = image.height * ratio;
          canvas.height = image.height;
        } else {
          canvas.width = image.width;
          canvas.height = image.width / ratio;
        }

        const x = (width - canvas.width) / -2;
        const y = (height - canvas.height) / -2;

        canvas.getContext('2d').drawImage(image, x, y, width, height);
        resolve(canvas);
      };

      image.src = dataUrl;
    });
  }
}
