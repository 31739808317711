export default class UNumber {
  /**
   * Generate random number
   * @param min Minimal number
   * @param max Maximal number
   */
  static rand(min, max) {
    return Math.floor(min + Math.random() * (max + 1 - min));
  }
}
