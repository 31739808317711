
import { Component, Model, Prop, Vue } from 'vue-property-decorator';

import Utils from '@/helpers/utils';

@Component
export default class BaseTextarea extends Vue {
  // State
  @Model('input') value;

  // Data
  @Prop(String) type;
  @Prop(String) label;
  @Prop(String) description;

  // Visual effects
  @Prop(Boolean) flat;
  @Prop(Boolean) dark;
  @Prop(Boolean) block;
  @Prop(String) color;
  @Prop(String) borderColor;
  @Prop(String) borderWidth;
  @Prop(String) classInput;
  @Prop(Boolean) readonly;
  @Prop(Boolean) hideLabel;
  @Prop(Boolean) hideDescription;

  @Prop({ type: String, default: () => '100%' }) width;
  @Prop({ type: String, default: () => 'auto%' }) height;
  @Prop({ type: String, default: () => '' }) placeholder;

  get localClasses() {
    return {
      flat: this.flat,
      dark: this.dark,
    };
  }

  get localStyles() {
    const styles = { ...this.localLabelStyles, ...this.colorStyles } as any;

    // Sizes
    if (this.borderWidth) styles.borderWidth = Utils.String.cssPropFit(this.borderWidth);

    return styles;
  }

  get localLabelStyles() {
    const styles = {} as any;

    // Sizes
    if (this.width) styles.width = Utils.String.cssPropFit(this.width);
    if (this.height) styles.height = Utils.String.cssPropFit(this.height);
    if (this.block) styles.width = '100%';

    return styles;
  }

  get colorStyles() {
    const styles = {
      color: `var(--vd-${this.dark || this.flat ? 'white' : 'black'})`,
    } as any;

    if (this.color) styles.backgroundColor = Utils.String.cssColorChain(this.color);
    if (this.color) styles.borderColor = Utils.String.cssColorChain(this.color);
    if (this.borderColor) styles.borderColor = Utils.String.cssColorChain(this.borderColor);
    if (this.flat) styles.backgroundColor = 'transparent';
    if (this.flat) styles.borderColor = 'transparent';

    return styles;
  }

  handleKeydown(event) {
    if (this.type === 'number' && event.key === 'e') event.preventDefault();
  }
}
