const IS_DEV = process.env.NODE_ENV === 'development';
const IS_DEBUG = !!localStorage.getItem('debug');

export default {
  IS_DEV,
  IS_DEBUG,

  URLS: {
    BASE: process.env.BASE_URL,

    get PUBLIC_ROOT() {
      return `${this.API.CURRENT}/public`;
    },

    get BILLS_ROOT() {
      return `${this.API.CURRENT}/service/generated`;
    },

    get BILLS_WATER_ROOT() {
      return `${this.BILLS_ROOT}/water`;
    },

    get BILLS_MSW_ROOT() {
      return `${this.BILLS_ROOT}/msw`;
    },

    API: {
      DEVELOPMENT: process.env.VUE_APP_API_SERVER_DEVELOPMENT,
      PRODUCTION: process.env.VUE_APP_API_SERVER_PRODUCTION,

      get CURRENT() {
        return IS_DEV ? this.DEVELOPMENT : this.PRODUCTION;
      },
    },
  },

  APPEARANCE: {
    THEME: localStorage.getItem('theme'),
  },

  TEXTS: {
    TRANSLIT_LANGUAGE: process.env.VUE_APP_TRANSLIT_LANGUAGE,
    APP_TITLE: process.env.VUE_APP_TITLE,
  },
};
