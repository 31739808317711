import { Route } from '@/router/@types';

export const routes: Route[] = [
  // ----------------------------- //
  // ----------- Front ----------- //
  // ----------------------------- //

  {
    path: '/develop',
    name: 'develop',
    component: 'DevelopPage',

    meta: {
      title: 'Develop',

      sidebar: null,
      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/',
    name: 'home',
    component: 'HomePage',

    meta: {
      auth: true,
      isHome: true,
      title: 'Головна',

      sidebar: null,
    },
  },

  // ------------------------------------------- //
  // --------------- Subscribers --------------- //
  // ------------------------------------------- //

  {
    path: '/subscribers',
    name: 'subscribers-all',
    component: 'subscribers/SubscribersListAllPage',

    meta: {
      auth: true,
      title: 'Абоненти',

      sidebar: null,
    },
  },

  { // TODO
    path: '/subscribers/debt',
    name: 'subscribers-debt',
    component: 'subscribers/SubscribersListDebtPage',

    meta: {
      auth: true,
      title: 'Боржники',

      sidebar: null,
    },
  },

  { // TODO
    path: '/subscribers/debt/:page',
    name: 'subscribers-debt-paged',
    component: 'subscribers/SubscribersListDebtPage',

    meta: {
      auth: true,
      title: 'Боржники',

      sidebar: null,
    },
  },

  {
    path: '/subscribers/:page',
    name: 'subscribers-all-paged',
    component: 'subscribers/SubscribersListAllPage',

    meta: {
      auth: true,
      title: 'Абоненти',

      sidebar: null,
    },
  },

  {
    path: '/subscribers/village/:village',
    name: 'subscribers-village',
    component: 'subscribers/SubscribersListVillagePage',

    meta: {
      auth: true,
      title: 'Абоненти',

      sidebar: null,
    },
  },

  {
    path: '/subscribers/village/:village/:page',
    name: 'subscribers-village-paged',
    component: 'subscribers/SubscribersListVillagePage',

    meta: {
      auth: true,
      title: 'Абоненти',

      sidebar: null,
    },
  },

  {
    path: '/subscribers/disabled/:village',
    name: 'subscribers-disabled',
    component: 'subscribers/SubscribersListDisabledPage',

    meta: {
      auth: true,
      title: 'Відключені',

      sidebar: null,
    },
  },

  {
    path: '/subscribers/disabled/:village/:page',
    name: 'subscribers-disabled-paged',
    component: 'subscribers/SubscribersListDisabledPage',

    meta: {
      auth: true,
      title: 'Відключені',

      sidebar: null,
    },
  },

  {
    path: '/subscribers/privileged/:village',
    name: 'subscribers-privileged',
    component: 'subscribers/SubscribersListPrivilegedPage',

    meta: {
      auth: true,
      title: 'Пільговики',

      sidebar: null,
    },
  },

  {
    path: '/subscribers/privileged/:village/:page',
    name: 'subscribers-privileged-paged',
    component: 'subscribers/SubscribersListPrivilegedPage',

    meta: {
      auth: true,
      title: 'Пільговики',

      sidebar: null,
    },
  },

  {
    path: '/subscribers/residents/:village',
    name: 'subscribers-resident',
    component: 'subscribers/SubscribersListResidentsPage',

    meta: {
      auth: true,
      title: 'Дачники',

      sidebar: null,
    },
  },

  {
    path: '/subscribers/residents/:village/:page',
    name: 'subscribers-resident-paged',
    component: 'subscribers/SubscribersListResidentsPage',

    meta: {
      auth: true,
      title: 'Дачники',

      sidebar: null,
    },
  },

  { // TODO
    path: '/subscribers/bill/:village',
    name: 'subscribers-bill',
    component: 'subscribers/SubscribersListBillPage',

    meta: {
      auth: true,
      title: 'Рахунки',

      sidebar: null,
    },
  },

  { // TODO
    path: '/subscribers/bill/:village/:page',
    name: 'subscribers-bill-paged',
    component: 'subscribers/SubscribersListBillPage',

    meta: {
      auth: true,
      title: 'Рахунки',

      sidebar: null,
    },
  },

  { // TODO
    path: '/subscribers/bill-warning/:village',
    name: 'subscribers-bill-warning',
    component: 'subscribers/SubscribersListBillWarningPage',

    meta: {
      auth: true,
      title: 'Рахунки-попередження',

      sidebar: null,
    },
  },

  { // TODO
    path: '/subscribers/bill-warning/:village/:page',
    name: 'subscribers-bill-warning-paged',
    component: 'subscribers/SubscribersListBillWarningPage',

    meta: {
      auth: true,
      title: 'Рахунки-попередження',

      sidebar: null,
    },
  },

  { // TODO
    path: '/subscribers/bank/:village',
    name: 'subscribers-payment-bank',
    component: 'payment/SubscribersListPaymentBankPage',

    meta: {
      auth: true,
      title: 'Банк',

      sidebar: null,
    },
  },

  { // TODO
    path: '/subscribers/bank/:village/:page',
    name: 'subscribers-payment-paged',
    component: 'subscribers/SubscribersListPaymentBankPage',

    meta: {
      auth: true,
      title: 'Банк',

      sidebar: null,
    },
  },

  { // TODO
    path: '/subscribers/cash/:village',
    name: 'subscribers-payment-cash',
    component: 'payment/SubscribersListPaymentCashPage',

    meta: {
      auth: true,
      title: 'Готівка',

      sidebar: null,
    },
  },

  { // TODO
    path: '/subscribers/cash/:village/:page',
    name: 'subscribers-payment-cash-paged',
    component: 'subscribers/SubscribersListPaymentCashPage',

    meta: {
      auth: true,
      title: 'Готівка',

      sidebar: null,
    },
  },

  {
    path: '/subscriber/:id',
    name: 'subscriber',
    component: 'subscribers/SubscriberItemPage',

    meta: {
      auth: true,
      title: 'Абонент',

      sidebar: null,
    },
  },

  // ----------------------------------------- //
  // --------------- Reporting --------------- //
  // ----------------------------------------- //

  {
    path: '/reporting',
    name: 'reporting-general',
    component: 'stats/StatsPage',

    meta: {
      auth: true,
      title: 'Звітність зведена',

      sidebar: null,
    },
  },

  {
    path: '/reporting/privat',
    name: 'reporting-privat',
    component: 'stats/PrivatDBF',

    meta: {
      auth: true,
      title: 'DBF для ПриватБанк',

      sidebar: null,
    },
  },

  {
    path: '/reporting/adjustments',
    name: 'reporting-adjustments',
    component: 'stats/Adjustments',

    meta: {
      auth: true,
      title: 'Перерахунки',

      sidebar: null,
    },
  },

  {
    path: '/messages',
    name: 'messages-general',
    component: 'stats/MessagesPage',

    meta: {
      auth: true,
      title: 'Повідомлення',

      sidebar: null,
    },
  },

  // --------------------------------------- //
  // --------------- Billing --------------- //
  // --------------------------------------- //

  { // TODO
    path: '/billing/transactions/:village',
    name: 'billing-transactions',
    component: 'billing/BillingTransactionsPage',

    meta: {
      auth: true,
      title: 'Транзакцiї',

      sidebar: null,
    },
  },

  { // TODO
    path: '/billing/recalculations/:village',
    name: 'billing-recalculations',
    component: 'billing/BillingRecalculationsPage',

    meta: {
      auth: true,
      title: 'Перерахунки',

      sidebar: null,
    },
  },

  // ------------------------------------ //
  // --------------- Auth --------------- //
  // ------------------------------------ //

  {
    path: '/auth',
    name: 'auth',
    component: 'admin/AdminAuthPage',

    meta: {
      auth: false,
      title: 'Увійти',

      sidebar: null,
    },
  },

  // ------------------------------------- //
  // --------------- Admin --------------- //
  // ------------------------------------- //

  {
    path: '/admin',
    name: 'admin-home',
    component: 'admin/AdminHomePage',

    meta: {
      auth: true,
      title: 'Панель управления',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/subscribers',
    name: 'admin-subscribers',

    redirect: {
      name: 'admin-subscribers-tab',
      params: {
        tab: 'list',
      },
    },
  },

  {
    path: '/admin/subscribers/:tab',
    name: 'admin-subscribers-tab',
    component: 'admin/subscribers/AdminSubscribersPage',

    meta: {
      auth: true,
      title: 'Панель управления - Абоненти',
      breadcrumb: 'Абоненти',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/subscribers/:tab/:page',
    name: 'admin-subscribers-tab-paged',
    component: 'admin/subscribers/AdminSubscribersPage',

    meta: {
      auth: true,
      title: 'Панель управления - Абоненти',
      breadcrumb: 'Абоненти',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/subscriber/:id',
    name: 'admin-subscriber',

    redirect: ({ params }) => ({
      name: 'admin-subscriber-tab',
      params: {
        tab: 'info',
        id: params.id,
      },
    }),
  },

  {
    path: '/admin/subscriber/:id/:tab',
    name: 'admin-subscriber-tab',
    component: 'admin/subscribers/AdminSubscriberItemPage',

    meta: {
      auth: true,
      title: 'Панель управления - Абонент',

      breadcrumb: 'Абонент',
      breadcrumbParent: '/admin/subscribers',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/payment-types',
    name: 'admin-payment-types',

    redirect: {
      name: 'admin-payment-types-tab',
      params: {
        tab: 'list',
      },
    },
  },

  {
    path: '/admin/payment-types/:tab',
    name: 'admin-payment-types-tab',
    component: 'admin/payment-types/AdminPaymentTypesPage',

    meta: {
      auth: true,
      title: 'Панель управления - Види оплат',
      breadcrumb: 'Види оплат',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/payment-types/:tab/:page',
    name: 'admin-payment-types-tab-paged',
    component: 'admin/payment-types/AdminPaymentTypesPage',

    meta: {
      auth: true,
      title: 'Панель управления - Види оплат',
      breadcrumb: 'Види оплат',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/payment-type/:id',
    name: 'admin-payment-type',

    redirect: ({ params }) => ({
      name: 'admin-payment-type-tab',
      params: {
        tab: 'info',
        id: params.id,
      },
    }),
  },

  {
    path: '/admin/payment-type/:id/:tab',
    name: 'admin-payment-type-tab',
    component: 'admin/payment-types/AdminPaymentTypeItemPage',

    meta: {
      auth: true,
      title: 'Панель управления - Вид оплати',

      breadcrumb: 'Вид оплати',
      breadcrumbParent: '/admin/payment-types',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/privileges',
    name: 'admin-privileges',

    redirect: {
      name: 'admin-privileges-tab',
      params: {
        tab: 'list',
      },
    },
  },

  {
    path: '/admin/privileges/:tab',
    name: 'admin-privileges-tab',
    component: 'admin/privileges/AdminPrivilegesPage',

    meta: {
      auth: true,
      title: 'Панель управления - Пільги',
      breadcrumb: 'Пільги',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/privileges/:tab/:page',
    name: 'admin-privileges-tab-paged',
    component: 'admin/privileges/AdminPrivilegesPage',

    meta: {
      auth: true,
      title: 'Панель управления - Пільги',
      breadcrumb: 'Пільги',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/privilege/:id',
    name: 'admin-privilege',

    redirect: ({ params }) => ({
      name: 'admin-privilege-tab',
      params: {
        tab: 'info',
        id: params.id,
      },
    }),
  },

  {
    path: '/admin/privilege/:id/:tab',
    name: 'admin-privilege-tab',
    component: 'admin/privileges/AdminPrivilegeItemPage',

    meta: {
      auth: true,
      title: 'Панель управления - Вид пiльги',

      breadcrumb: 'Вид пiльги',
      breadcrumbParent: '/admin/privileges',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/villages',
    name: 'admin-villages',

    redirect: {
      name: 'admin-villages-tab',
      params: {
        tab: 'list',
      },
    },
  },

  {
    path: '/admin/villages/:tab',
    name: 'admin-villages-tab',
    component: 'admin/villages/AdminVillagesPage',

    meta: {
      auth: true,
      title: 'Панель управления - Села',
      breadcrumb: 'Села',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/villages/:tab/:page',
    name: 'admin-villages-tab-paged',
    component: 'admin/villages/AdminVillagesPage',

    meta: {
      auth: true,
      title: 'Панель управления - Села',
      breadcrumb: 'Села',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/village/:id',
    name: 'admin-village',

    redirect: ({ params }) => ({
      name: 'admin-village-tab',
      params: {
        tab: 'info',
        id: params.id,
      },
    }),
  },

  {
    path: '/admin/village/:id/:tab',
    name: 'admin-village-tab',
    component: 'admin/villages/AdminVillageItemPage',

    meta: {
      auth: true,
      title: 'Панель управления - Село',

      breadcrumb: 'Село',
      breadcrumbParent: '/admin/villages',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/record-types',
    name: 'admin-record-types',

    redirect: {
      name: 'admin-record-types-tab',
      params: {
        tab: 'list',
      },
    },
  },

  {
    path: '/admin/record-types/:tab',
    name: 'admin-record-types-tab',
    component: 'admin/record-types/AdminRecordTypesPage',

    meta: {
      auth: true,
      title: 'Панель управления - Типи обліку',
      breadcrumb: 'Типи обліку',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/record-types/:tab/:page',
    name: 'admin-record-types-tab-paged',
    component: 'admin/record-types/AdminRecordTypesPage',

    meta: {
      auth: true,
      title: 'Панель управления - Типи обліку',
      breadcrumb: 'Типи обліку',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/record-type/:id',
    name: 'admin-record-type',

    redirect: ({ params }) => ({
      name: 'admin-record-type-tab',
      params: {
        tab: 'info',
        id: params.id,
      },
    }),
  },

  {
    path: '/admin/record-type/:id/:tab',
    name: 'admin-record-type-tab',
    component: 'admin/record-types/AdminRecordTypeItemPage',

    meta: {
      auth: true,
      title: 'Панель управления - Тип обліку',

      breadcrumb: 'Тип обліку',
      breadcrumbParent: '/admin/record-types',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/transactions',
    name: 'admin-transactions',

    redirect: {
      name: 'admin-transactions-tab',
      params: {
        tab: 'list',
      },
    },
  },

  {
    path: '/admin/transactions/:tab',
    name: 'admin-transactions-tab',
    component: 'admin/transactions/AdminTransactionsPage',

    meta: {
      auth: true,
      title: 'Панель управления - Транзакції',
      breadcrumb: 'Транзакції',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/transactions/:tab/:page',
    name: 'admin-transactions-tab-paged',
    component: 'admin/transactions/AdminTransactionsPage',

    meta: {
      auth: true,
      title: 'Панель управления - Транзакції',
      breadcrumb: 'Транзакції',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/transaction/:id',
    name: 'admin-transaction',

    redirect: ({ params }) => ({
      name: 'admin-transaction-tab',
      params: {
        tab: 'info',
        id: params.id,
      },
    }),
  },

  {
    path: '/admin/transaction/:id/:tab',
    name: 'admin-transaction-tab',
    component: 'admin/transactions/AdminTransactionItemPage',

    meta: {
      auth: true,
      title: 'Панель управления - Транзакція',

      breadcrumb: 'Транзакція',
      breadcrumbParent: '/admin/transactions',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/users-legal',
    name: 'admin-users-legal',

    redirect: {
      name: 'admin-users-legal-tab',
      params: {
        tab: 'list',
      },
    },
  },

  {
    path: '/admin/users-legal/:tab',
    name: 'admin-users-legal-tab',
    component: 'admin/users-legal/AdminUsersLegalPage',

    meta: {
      auth: true,
      title: 'Панель управления - Юридичнi особи',
      breadcrumb: 'Юридичнi особи',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/users-legal/:tab/:page',
    name: 'admin-users-legal-tab-paged',
    component: 'admin/users-legal/AdminUsersLegalPage',

    meta: {
      auth: true,
      title: 'Панель управления - Юридичнi особи',
      breadcrumb: 'Юридичнi особи',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/user-legal/:id',
    name: 'admin-user-legal',

    redirect: ({ params }) => ({
      name: 'admin-user-legal-tab',
      params: {
        tab: 'info',
        id: params.id,
      },
    }),
  },

  {
    path: '/admin/user-legal/:id/:tab',
    name: 'admin-user-legal-tab',
    component: 'admin/users-legal/AdminUserLegalItemPage',

    meta: {
      auth: true,
      title: 'Панель управления - Юридична особа',

      breadcrumb: 'Юридична особа',
      breadcrumbParent: '/admin/users-legal',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/firms',
    name: 'admin-firms',

    redirect: {
      name: 'admin-firms-tab',
      params: {
        tab: 'list',
      },
    },
  },

  {
    path: '/admin/firms/:tab',
    name: 'admin-firms-tab',
    component: 'admin/firms/AdminFirmsPage',

    meta: {
      auth: true,
      title: 'Панель управления - Господарства',
      breadcrumb: 'Господарства',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/firms/:tab/:page',
    name: 'admin-firms-tab-paged',
    component: 'admin/firms/AdminFirmsPage',

    meta: {
      auth: true,
      title: 'Панель управления - Господарства',
      breadcrumb: 'Господарства',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/firm/:id',
    name: 'admin-firm',

    redirect: ({ params }) => ({
      name: 'admin-firm-tab',
      params: {
        tab: 'info',
        id: params.id,
      },
    }),
  },

  {
    path: '/admin/firm/:id/:tab',
    name: 'admin-firm-tab',
    component: 'admin/firms/AdminFirmItemPage',

    meta: {
      auth: true,
      title: 'Панель управления - Господарство',

      breadcrumb: 'Господарство',
      breadcrumbParent: '/admin/firms',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/operators',
    name: 'admin-operators',

    redirect: {
      name: 'admin-operators-tab',
      params: {
        tab: 'list',
      },
    },
  },

  {
    path: '/admin/operators/:tab',
    name: 'admin-operators-tab',
    component: 'admin/operators/AdminOperatorsPage',

    meta: {
      auth: true,
      title: 'Панель управления - Користувачі',
      breadcrumb: 'Користувачі',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/operators/:tab/:page',
    name: 'admin-operators-tab-paged',
    component: 'admin/operators/AdminOperatorsPage',

    meta: {
      auth: true,
      title: 'Панель управления - Користувачі',
      breadcrumb: 'Користувачі',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  {
    path: '/admin/operator/:id',
    name: 'admin-operator',

    redirect: ({ params }) => ({
      name: 'admin-operator-tab',
      params: {
        tab: 'info',
        id: params.id,
      },
    }),
  },

  {
    path: '/admin/operator/:id/:tab',
    name: 'admin-operator-tab',
    component: 'admin/operators/AdminOperatorItemPage',

    meta: {
      auth: true,
      title: 'Панель управления - Користувач',

      breadcrumb: 'Користувач',
      breadcrumbParent: '/admin/operators',

      header: () => import('@/components/common/TheHeaderAdmin.vue'),
    },
  },

  // ------------------------------------ //
  // -------------- Others -------------- //
  // ------------------------------------ //

  {
    path: '/billing/chart',
    name: 'billing-chart',
    component: 'billing/BillingChartsPage',

    meta: {
      auth: true,
      title: 'Графiк',
      sidebar: null,
    },
  },

  {
    path: '*',
    name: 'error-404',
    component: 'Error404Page',

    meta: {
      title: 'Сторінку не знайдено',

      sidebar: null,
    },
  },
];

export default routes;
