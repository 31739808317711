import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import API from '@/helpers/API';

@Module({ namespaced: true })
export default class Stats extends VuexModule {
  totalStats = [];
  villagesStats = [];

  // -------------------------------- //
  // ------------ Getters ----------- //
  // -------------------------------- //

  // ...

  // -------------------------------- //
  // ------------ Actions ----------- //
  // -------------------------------- //

  @Action
  loadTotalStats() {
    return API.get('stats')
      .setErrorVisibility(false)
      .execute()
      .then((res) => {
        if (res.ok && res.msg.data) {
          this.context.commit('setTotalStats', res.msg.data);
        }
      });
  }

  @Action
  loadVillagesStats(dateRange) {

    const range = dateRange || {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0)
    }

    return API.get(`stats/villages/${range.startDate.Ymd()}/${range.endDate.Ymd()}`)
      .setErrorVisibility(false)
      .execute()
      .then((res) => {
        if (res.ok && res.msg.data) {
          this.context.commit('setVillagesStats', res.msg.data);
        }
      });
  }

  // ----------------------------------- //
  // ------------ Mutations ------------ //
  // ----------------------------------- //

  @Mutation
  setTotalStats(data) {
    this.totalStats = data;
  }

  @Mutation
  setVillagesStats(data) {
    this.villagesStats = data;
  }
}
