import Vue from 'vue';
import App from '@/App.vue';

import '@/assets/stylesheets/scss/app.scss';
import '@/plugins';
import '@/directives';
import '@/components';
import '@/prototypes';

import IconStore from '@/helpers/IconStore';
import i18n from '@/plugins/i18n';
import vuex from '@/store';
import router from '@/router';

Vue.config.performance = true;
Vue.config.productionTip = true;
window.timelapseStart = Date.now();

(async () => {
  // Waiting for load all icons which need to be preloaded
  await IconStore.init();

  // Start initializing Vue-app
  new Vue({
    i18n,
    router,
    store: vuex,
    render: (h) => h(App),
  }).$mount('#app');
})();

// Test Jenkins Auto-deploy #2
