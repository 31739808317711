
import { Component, Emit, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import Utils from '@/helpers/utils';
import BaseMenu from '@/components/ui/BaseMenu.vue';

@Component
export default class BaseSelect extends Vue {
  // State
  @Model('input') value;
  @Prop({ type: Boolean }) opened;

  // Data
  @Prop(Boolean) object;
  @Prop(Boolean) filterable;
  @Prop(Boolean) enableInput;
  @Prop({ type: Array, default: () => [] }) items;
  @Prop({ type: Object, default: () => ({}) }) groupsTexts;

  // Visual effects
  @Prop(Object) menu;
  @Prop(String) label;
  @Prop(String) color;
  @Prop(String) borderColor;
  @Prop(String) borderWidth;
  @Prop(String) description;
  @Prop(Boolean) flat;
  @Prop(Boolean) dark;
  @Prop(Boolean) block;
  @Prop(Boolean) hideLabel;
  @Prop(Boolean) hideDescription;
  @Prop({ type: String, default: () => '200px' }) width;
  @Prop({ type: String, default: () => 'text' }) itemText;
  @Prop({ type: String, default: () => 'value' }) itemKey;
  @Prop({ type: String, default: () => 'mdi-menu-down' }) icon;
  @Prop({ type: String, default: () => 'grey' }) iconColor;
  @Prop({ type: String, default: () => '' }) placeholder;

  isOpened = false;
  current = null;
  inputField = null;
  inputValue = null;

  get localClasses() {
    return {
      flat: this.flat,
      dark: this.dark,
      _opened: this.isOpened,
      _empty: !this.current,
      '_has-description': !this.hideDescription,
    };
  }

  get localWrapperStyles() {
    const styles = {} as any;

    // Sizes
    if (this.width) styles.width = Utils.String.cssPropFit(this.width);
    if (this.block) styles.width = '100%';

    return styles;
  }

  get colorStyles() {
    const styles = {
      color: `var(--vd-${this.dark || this.flat ? 'white' : 'black'})`,
    } as any;

    if (this.color) styles.backgroundColor = Utils.String.cssColorChain(this.color);
    if (this.color) styles.borderColor = Utils.String.cssColorChain(this.color);
    if (this.borderColor) styles.borderColor = Utils.String.cssColorChain(this.borderColor);
    if (this.flat) styles.backgroundColor = 'transparent';
    if (this.flat) styles.borderColor = 'transparent';

    return styles;
  }

  get menuProps() {
    return {
      fillWidth: true,
      yOffset: this.menuYOffset,
      ...this.menu,
    };
  }

  get menuYOffset() {
    let offset = 0;

    if (this.inputField) {
      const inputCnt = this.inputField.$el;
      const descriptionCnt = inputCnt.querySelector('.v-base-input__description');
      offset -= descriptionCnt?.clientHeight;
    }

    return String(offset - 1);
  }

  get readonly() {
    return !this.filterable && !this.enableInput;
  }

  get currentItem() {
    const dataIsObject = !!this.filteredItems[0]?.[this.itemKey];
    if (dataIsObject !== undefined && !this.object) {
      return this.filteredItems.find((item) => item[this.itemKey] === this.current);
    }

    return this.current;
  }

  get title() {
    return this.currentItem?.[this.itemText];
  }

  get filteredItems() {
    if (!this.filterable || !this.inputValue) return this.items;
    return this.items.filter((item) => String(item[this.itemText]).includes(this.inputValue));
  }

  get groups() {
    const groups = { '': [] };

    this.filteredItems.forEach((item) => {
      if (!item.group) {
        groups[''].push(item);
      } else {
        if (!groups[item.group]) groups[item.group] = [];
        groups[item.group].push(item);
      }
    });

    return groups;
  }

  @Watch('title') onTitleChange() {
    if (!this.inputValue) this.resetInputValue();
  }

  @Watch('value') onValueChange(after) {
    this.current = after;
    this.resetInputValue();
  }

  @Watch('opened') onOpenedStateChange(after) {
    this.isOpened = after;
    this.$nextTick(() => (this.$refs.menuRef as BaseMenu)?.calculatePosition());
  }

  @Watch('$refs.input') onRefsUpdated() {
    this.inputField = this.$refs.input;
  }

  mounted() {
    this.onRefsUpdated();
    this.onValueChange(this.value);
    this.onOpenedStateChange(this.opened);

    this.$on('input', this.onValueChange);
    this.$on('update:opened', this.onOpenedStateChange);
  }

  beforeDestroy() {
    this.$off('input', this.onValueChange);
    this.$off('update:opened', this.onOpenedStateChange);
  }

  resetInputValue(value = this.title) {
    this.inputValue = value;
  }

  onClickItem(item) {
    let value = item;
    if (item && !this.object && Object.prototype.hasOwnProperty.call(item, this.itemKey)) {
      value = item[this.itemKey];
    }

    this.$emit('input', value);
    this.close();
  }

  onClickInput() {
    if (this.readonly) this.toggle();
  }

  onInputValueChange(value) {
    this.open();
    this.$emit('change-input-value', value);
  }

  @Emit()
  toggle() {
    this.$emit('update:opened', !this.isOpened);
  }

  @Emit()
  open() {
    this.$emit('update:opened', true);
  }

  @Emit()
  close() {
    this.$emit('update:opened', false);
  }
}
