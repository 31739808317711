import cyrillicToTranslit from 'cyrillic-to-translit-js';
import Config from '@/config';
import UNumber from './Number';
import UConst from './Consts';

export default class UString {
  private static translitFabric: {
    transform(input: string, spaceReplacement?: string): string;
    reverse(input: string, spaceReplacement?: string): string;
  };

  private static initTranslitFabric(preset: any = Config.TEXTS.TRANSLIT_LANGUAGE) {
    if (UString.translitFabric) return;
    UString.translitFabric = cyrillicToTranslit({ preset });
  }

  /**
   * Generate url-query string from object
   * @param obj Origin object
   * @param parName Sub-parameter name
   */
  static serialize(obj, parName?): string {
    if (!obj) return '';
    const isLeveling = parName !== undefined;

    const str = Object.keys(obj).map((p) => {
      let pName = p;
      if (isLeveling) pName = `${parName}[${pName}]`;
      if (typeof obj[p] === 'object') return UString.serialize(obj[p], pName);

      return `${encodeURIComponent(pName)}=${encodeURIComponent(obj[p])}`;
    });

    return `${!isLeveling ? '?' : ''}${str.join('&')}`;
  }

  /**
   * Reverse chars in string
   * @param str Origin string
   */
  static reverse(str: string) {
    return String(str).split('').reverse().join('');
  }

  /**
   * Change case of first char to upper
   * @param str
   */
  static ucfirst(str = '') {
    return String(str).charAt(0).toUpperCase() + String(str).substr(1, String(str).length - 1);
  }

  /**
   * Returns string with right suffix depends on count
   * @param one If count ends with 1
   * @param two If count ends with 2-4
   * @param many If count ends with 5-0
   * @param count
   */
  static suffix(one, two, many, count) {
    if (!Number.isFinite(count)) return '';

    if (count < 10) {
      if (count === 1) return one;
      if (count > 1 && count < 5) return two;

      return many;
    }

    return UString.suffix(one, two, many, count % 10);
  }

  /**
   * Generate random hash
   * @param len Length of string
   * @param type Enum of chars for hash
   */
  static hash(len = 32, type: 'all' | 'numbers' | 'letters' = 'all') {
    let hash = '';
    for (let i = 0; i < len; i += 1) {
      const chance = UNumber.rand(0, 2);
      if (type !== 'letters' && (chance === 0 || type === 'numbers')) {
        hash += UNumber.rand(0, 9);
      } else {
        const upperCaseRange = UNumber.rand(65, 90);
        const lowerCaseRange = UNumber.rand(97, 122);
        hash += String.fromCharCode(chance === 1 ? upperCaseRange : lowerCaseRange);
      }
    }

    return hash;
  }

  /**
   * Convert date to "31.12.9999 23:59" format
   * @param date {Date} Origin date
   */
  static toDateTime(date = new Date()) {
    return `${UString.toDate(date)} ${UString.toTime(date)}`;
  }

  /**
   * Convert date to "31.12.9999" format
   * @param date {Date} Origin date
   */
  static toDate(date = new Date()) {
    return date.toLocaleDateString().split('-').join('.');
  }

  /**
   * Convert date to "31.12" format
   * @param date {Date} Origin date
   */
  static toShortDate(date = new Date()) {
    return UString.toDate(date).substr(0, 5);
  }

  /**
   * Convert date to "23:59" format
   * @param date {Date} Origin date
   */
  static toTime(date = new Date()) {
    return date.toLocaleTimeString().substr(0, 5);
  }

  /**
   * Convert date to "5 seconds ago" format
   * @param date {Date} Origin date
   */
  static toAgoFormat(date = new Date()) {
    const data = UString.getAgoDifference(date);
    let text = '';

    switch (data.type) {
      case 'milliseconds':
        text = UString.suffix(
          'milliseconds.1-ago',
          'milliseconds.2-ago',
          'milliseconds.3-ago',
          data.difference,
        );
        break;

      case 'seconds':
        text = UString.suffix(
          'seconds.1-ago',
          'seconds.2-ago',
          'seconds.3-ago',
          data.difference,
        );
        break;

      case 'minutes':
        text = UString.suffix(
          'minutes.1-ago',
          'minutes.2-ago',
          'minutes.3-ago',
          data.difference,
        );
        break;

      case 'hours':
        text = UString.suffix(
          'hours.1-ago',
          'hours.2-ago',
          'hours.3-ago',
          data.difference,
        );
        break;

      case 'days':
        text = UString.suffix(
          'days.1-ago',
          'days.2-ago',
          'days.3-ago',
          data.difference,
        );
        break;

      case 'weeks':
        text = UString.suffix(
          'weeks.1-ago',
          'weeks.2-ago',
          'weeks.3-ago',
          data.difference,
        );
        break;

      case 'months':
        text = UString.suffix(
          'months.1-ago',
          'months.2-ago',
          'months.3-ago',
          data.difference,
        );
        break;

      case 'years':
        text = UString.suffix(
          'years.1-ago',
          'years.2-ago',
          'years.3-ago',
          data.difference,
        );
        break;
    }

    return {
      text,
      difference: data.difference,
    };
  }

  /**
   * Convert number to "999.999.99" format
   * @param num Origin number
   * @param delimiter Delimiter between hundreds
   */
  static toMoney(num, delimiter = ' ') {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${delimiter}`);
  }

  /**
   * Convert number to "+00 000 000 00 00"
   * @param str
   * @param d
   */
  static toPhone(str, d = ' ') {
    const mask = /(\d{2})(\d{2})(\d{3})(\d{3})(\d+?)/;
    const text = UString.reverse(
      UString.reverse(str).replace(mask, `$1${d}$2${d}$3${d}$4${d}$5`),
    );

    return `+${text}`;
  }

  /**
   * Get date difference from now time
   * @param date {Date} Origin date
   */
  static getAgoDifference(date = new Date()) {
    let difference = Date.now() - date.getTime();

    if (difference < UConst.TP_SECOND) {
      return { difference, type: 'milliseconds' };
    }

    if (difference < UConst.TP_MINUTE) {
      difference = Math.round(difference / UConst.TP_SECOND);
      return { difference, type: 'seconds' };
    }

    if (difference < UConst.TP_HOUR) {
      difference = Math.round(difference / UConst.TP_MINUTE);
      return { difference, type: 'minutes' };
    }

    if (difference < UConst.TP_DAY) {
      difference = Math.round(difference / UConst.TP_HOUR);
      return { difference, type: 'hours' };
    }

    if (difference < UConst.TP_WEEK) {
      difference = Math.round(difference / UConst.TP_DAY);
      return { difference, type: 'days' };
    }

    if (difference < UConst.TP_MONTH) {
      difference = Math.round(difference / UConst.TP_WEEK);
      return { difference, type: 'weeks' };
    }

    if (difference < UConst.TP_YEAR) {
      difference = Math.round(difference / UConst.TP_MONTH);
      return { difference, type: 'months' };
    }

    difference = Math.round(difference / UConst.TP_YEAR);
    return { difference, type: 'years' };
  }

  /**
   * Composition of a row with a unit of size, if the last is missing
   * @param value Css-property
   * @param units Unit size
   */
  static cssPropFit(value, units = 'px') {
    if (value === 'auto') return value;

    const textValue = String(Math.round(value) || value);
    const ifUnitNotSpecified = parseInt(value, 10).toString().length === textValue.length;
    return ifUnitNotSpecified ? `${value}${units}` : value;
  }

  /**
   * Composition of a string with color, checking for the presence of variables
   * @param color Color
   */
  static cssColorChain(color) {
    if ((color || '')[0] === '#') return color;
    return `var(--btn-${color}, var(--vd-${color}, var(--${color}, ${color})))`;
  }

  /**
   * Make a translit of string
   * @param value Input String
   */
  static translit(value) {
    UString.initTranslitFabric();
    return UString.translitFabric.transform(value, '_');
  }

  /**
   * Reverse a translit of string
   * @param value Input translited String
   */
  static translitReverse(value) {
    UString.initTranslitFabric();
    return UString.translitFabric.reverse(value, ' ');
  }
}
