
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseBreadcrumbs extends Vue {
  @Prop(Boolean) fill;

  links = [];
  passedRoutes = [];

  mounted() {
    this.links = [];
    this.passedRoutes = [];

    const pathParts = this.$route.path.substr(1).split('/');

    for (let i = 1; i <= pathParts.length; i += 1) {
      const pathPart = `/${pathParts.slice(0, i).join('/')}`;
      const route = this.getRoute(pathPart);

      if (route.meta?.breadcrumbParent) {
        this.appendLink(this.getRoute(route.meta.breadcrumbParent));
      }

      this.appendLink(route);
    }
  }

  appendLink(route) {
    if (!this.checkRouteUnique(route)) return;

    this.passedRoutes.push(route.name);
    this.links.push({
      title: route.meta?.breadcrumb || route.meta?.title,
      to: {
        name: route.name,
        params: route.params,
      },
    });
  }

  getRoute(path) {
    return (this.$router as any).match(path);
  }

  checkRouteUnique(route) {
    return route.name !== 'error-404' && !this.passedRoutes.includes(route.name);
  }
}
