import UConst from './modules/Consts';
import UNumber from './modules/Number';
import UString from './modules/String';
import UDocument from './modules/Document';
import UConsole from './modules/Console';
import UImage from './modules/Image';
import UGeo from './modules/Geo';

export default class Utils {
  public static Const = UConst;
  public static Number = UNumber;
  public static String = UString;
  public static Document = UDocument;
  public static Console = UConsole;
  public static Image = UImage;
  public static Geo = UGeo;
}
