import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import MobileDetect from 'mobile-detect';
import platform from 'platform';

export interface ErrorData {
  type?: ErrorDataTypes;
  title?: string;
  msg: string;
}

export enum ErrorDataTypes {
  WARNING,
  FAILED,
  SUCCESS
}

@Module({ namespaced: true })
export default class Global extends VuexModule {
  md = new MobileDetect(window.navigator.userAgent);
  error: ErrorData = null;
  editableSubscriber = null;

  // --------------------------------- //
  // ------------ Getters ------------ //
  // --------------------------------- //

  get editableSubscriberId(){
    return this.editableSubscriber
  }

  get isMobile() {
    return this.md.mobile();
  }

  get isIOS() {
    return platform.name === 'Safari' && platform.os.family === 'iOS';
  }

  get isChromium() {
    const supporters = ['Chrome', 'Chrome Mobile', 'Microsoft Edge'];
    return supporters.indexOf(platform.name) >= 0;
  }

  get rolesList() {
    return [
      { id: 'admin', name: 'Адміністратор' },
      { id: 'operator', name: 'Оператор' },
      { id: 'booker', name: 'Бухгалтер' },
    ];
  }

  // --------------------------------- //
  // ------------ Actions ------------ //
  // --------------------------------- //

  // TODO

  // ----------------------------------- //
  // ------------ Mutations ------------ //
  // ----------------------------------- //

  @Mutation setEditableSubscriber(value){
    this.editableSubscriber = value
  }

  @Mutation setError(data) {
    this.error = data;
  }
}
