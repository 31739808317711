
import { Component, Prop, Vue } from 'vue-property-decorator';
import API from '@/helpers/API';

@Component
export default class BaseDataTable extends Vue {
  @Prop(Array) items;
  @Prop(Array) headers;
  @Prop(Object) filters;
  @Prop(Boolean) smallAnchor;

  sortingKey = null;
  sortingDesc = false;
  openedFilteringWindowFor = null;
  isShownScroller = false;
  hiddenFields = [
    'description',
    'type_name',
    'user_pkg_email'
  ];
  hiddenFieldIndex = 0;

  deleteTransaction(item) {
    if(!confirm('Удалить трансакцию?')) return

    return API.delete(`transaction/${item.id}`)
      .execute()
      .then((res) => {
        if (res.ok) location.reload()
      });
  }

  editTransaction(id){
    location.href = `/admin/transaction/${id}/edit`
  }

  get scroller() {
    return this.$refs.scroller as HTMLDivElement;
  }

  get sortedAndFilteredItems() {
    const header = this.headers.find((f) => f.key === this.sortingKey);

    return this.filteredItems.slice().sort(header?.sort || ((a, b) => {
      if (!this.sortingKey) return 0;

      let result = 0;
      let aValue = a[this.sortingKey];
      let bValue = b[this.sortingKey];

      if (header.sortKey) {
        if (aValue) aValue = aValue[header.sortKey];
        if (bValue) bValue = bValue[header.sortKey];
      }

      if (typeof aValue === 'string' || typeof bValue === 'string') {
        result = String(aValue).localeCompare(bValue);
      } else if (typeof aValue === 'number' || typeof bValue === 'number') {
        result = aValue - bValue;
      }

      if (!this.sortingDesc) result *= -1;
      return result;
    }));
  }

  get filteredItems() {
    return this.items.filter((item) => {
      for (const key in this.filters) {
        if (!Object.prototype.hasOwnProperty.call(this.filters, key)) continue;

        const filter = this.filters[key];
        const type = filter.type || 'include';

        switch (type) {
          case 'include': {
            if (!item[key] && filter.items.length) return false;

            for (const filterItem of filter.items) {
              const equalsInObject = (
                filterItem.key
                && String(filterItem.data[filterItem.key]) === String(item[key][filterItem.key])
              );

              const equalsAnObject = (
                !filterItem.key
                && String(filterItem.data) === String(item[key])
              );

              if (equalsInObject || equalsAnObject) return true;
            }

            if (filter.items.length) return false;
            break;
          }

          default:
            // eslint-disable-next-line
            console.error('Undefined filtering type');
        }
      }

      return true;
    });
  }

  mounted() {
    this.scroller.addEventListener('scroll', this.updateScrollerButtonVisibility);
    setTimeout(() => this.updateScrollerButtonVisibility(), 1000);
  }

  destroyed() {
    this.scroller.removeEventListener('scroll', this.updateScrollerButtonVisibility);
  }

  getTHeadClasses(header) {
    return [{
      _filterable: !!this.$slots[`filter.${header.key}`],
      _active: this.sortingKey === header.key,
      _reverse: this.sortingKey === header.key && this.sortingDesc,
    }, header.key];
  }

  getTHeadStyles(header) {
    return {
      width: header.width,
      textAlign: header.align,
    };
  }

  nextHiddenField(){
    this.hiddenFields = []
  }

  toggleSort(key) {
    if (this.sortingKey === key) {
      if (this.sortingDesc) {
        this.sortingKey = null;
        this.sortingDesc = false;
        return;
      }

      this.sortingDesc = true;
      return;
    }

    this.sortingKey = key;
    this.sortingDesc = false;
  }

  detectMismatch(item, itemIndex){
    if (itemIndex + 1 > this.sortedAndFilteredItems.length) return false
    const diff = item.balance_water_before - this
      .sortedAndFilteredItems[itemIndex + 1]?.balance_water_after
    console.log(itemIndex, diff)
    return Math.abs(diff) > 1
  }

  toggleFilterWindow(key, e) {
    let element = e.target;
    do {
      if (element.classList.contains('v-base-table__modal')) return;
      if (element.classList.contains('v-base-table__icon-wrapper')) return;
      element = element.parentElement;
    }
    while (element);

    if (!this.$slots[`filter.${key}`]) return;

    if (this.openedFilteringWindowFor === key) {
      this.openedFilteringWindowFor = null;
      return;
    }

    this.openedFilteringWindowFor = key;
  }

  scroll() {
    this.scroller.scroll({
      left: this.scroller.scrollWidth - this.scroller.offsetWidth,
      behavior: 'smooth',
    });
  }

  updateScrollerButtonVisibility() {
    const scrollLimit = this.scroller.scrollWidth - this.scroller.offsetWidth;
    this.isShownScroller = this.scroller.scrollLeft < scrollLimit;
  }
}
