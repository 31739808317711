import Vue from 'vue';
import Router from 'vue-router';

import Config from '@/config';
import vuex from '@/store';
import Utils from '@/helpers/utils';

import { Route } from './@types';
import { routes } from './routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: Config.URLS.BASE,
  routes: routes.map((route: Route) => ({
    ...route,
    meta: {
      ...route.meta,
      title: route.meta?.title,
    },
    component: () => import(`@/pages/${route.component}.vue`),
  })) as any,
});

router.beforeEach(async (to, fr, next) => {
  let nextPage = null;

  // Call preInit method from vuex for Application asynchronously or not
  const { isPreInited } = (vuex.state as any).Init || {};
  if ((!to.meta || !to.meta?.vuexPreventPreInit) && !isPreInited) {
    if (to.meta?.vuexAsyncPreInit) {
      vuex.dispatch('Init/preInit', to.meta?.vuexPreInitConfig);
    } else {
      await vuex.dispatch('Init/preInit', to.meta?.vuexPreInitConfig);
    }
  }

  // Call preInit method from vuex for current route
  if (to.meta && to.meta?.vuexInit) {
    await vuex.dispatch(to.meta?.vuexInit, {
      route: to,
      data: to.meta?.vuexData,
    });
  }

  // Move to auth page if need auth-state,
  // or to home, if needn't,
  // or just skip if auth-state is not matter
  if (to.meta && typeof to.meta?.auth === 'boolean') {
    const { isAuth } = vuex.state[to.meta?.authModule || 'User'];
    const isNeedAuth = to.meta?.auth && !isAuth;
    const isAuthed = to.meta?.auth === false && isAuth;

    routes.forEach((route) => {
      if (nextPage) return;

      const isSameGroup = route.meta?.group === to.meta?.group;
      const isAuthInGroup = isSameGroup && route.meta?.auth === false;
      const isHomeInGroup = isSameGroup && route.meta?.isHome;

      if ((isNeedAuth && isAuthInGroup) || (isAuthed && isHomeInGroup)) nextPage = route.name;
    });
  }

  Utils.Console.create()
    .addTags('APP', 'Router')
    .end(nextPage
      ? `Redirecting: ${to.name} -> ${nextPage}`
      : `Loading route: ${to.name}`);

  return next(nextPage ? { name: nextPage } : undefined);
});

router.afterEach((route) => {
  Utils.Console.create()
    .addTags('APP', 'Router')
    .end('Route loaded');

  Utils.Document.setDocumentTitle(route);
  Utils.Document.setLoading(false);
});

export default router;
