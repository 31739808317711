export default class UGeo {
  /**
   * Transpose points ({lat, lng} => {lng, lat})
   * @param points
   */
  static transpose(points: number[][]) {
    const validPoints = [];
    points.filter((p) => p).forEach((point) => validPoints.push([point[1], point[0]]));
    return validPoints;
  }
}
