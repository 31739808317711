/* eslint-disable */
import { load } from '@/plugins/i18n/helper';

export default {
  'testing-section': {
    'test': 'Это тест',
    'longtext': load('ru', 'test'),
  },

  'time-points': {
    'milliseconds': {
      '1': 'милисекунда',
      '1.ago': 'милисекунду',
      '2': 'милисекунды',
      '2.ago': 'милисекунды',
      '3': 'милисекунд',
      '3.ago': 'милисекунд',
    },
    'seconds': {
      '1': 'секунда',
      '1.ago': 'секунду',
      '2': 'секунды',
      '2.ago': 'секунды',
      '3': 'секунд',
      '3.ago': 'секунд',
    },
    'minutes': {
      '1': 'минута',
      '1.ago': 'минуту',
      '2': 'минуты',
      '2.ago': 'минуты',
      '3': 'минут',
      '3.ago': 'минут',
    },
    'hours': {
      '1': 'час',
      '1.ago': 'час',
      '2': 'часа',
      '2.ago': 'часа',
      '3': 'часов',
      '3.ago': 'часов',
    },
    'days': {
      '1': 'день',
      '1.ago': 'день',
      '2': 'дня',
      '2.ago': 'дня',
      '3': 'дней',
      '3.ago': 'дней',
    },
    'weeks': {
      '1': 'неделя',
      '1.ago': 'неделю',
      '2': 'недели',
      '2.ago': 'недели',
      '3': 'недель',
      '3.ago': 'недель',
    },
    'months': {
      '1': 'месяц',
      '1.ago': 'месяц',
      '2': 'месяца',
      '2.ago': 'месяца',
      '3': 'месяцев',
      '3.ago': 'месяцев',
    },
    'years': {
      '1': 'год',
      '1.ago': 'год',
      '2': 'года',
      '2.ago': 'года',
      '3': 'лет',
      '3.ago': 'лет',
    },
  },
};
