import Vue from 'vue';

import BaseTabs from '@/components/ui/BaseTabs.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseMenu from '@/components/ui/BaseMenu.vue';
import BaseImage from '@/components/ui/BaseImage.vue';
import BaseModal from '@/components/ui/BaseModal.vue';
import BaseInput from '@/components/ui/BaseInput.vue';
import BaseTextarea from '@/components/ui/BaseTextarea.vue';
import BaseDateInput from '@/components/ui/BaseDateInput.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import BaseAsyncList from '@/components/ui/BaseAsyncList.vue';
import BaseDatePicker from '@/components/ui/BaseDatePicker.vue';
import BaseProgressBar from '@/components/ui/BaseProgressBar.vue';
import BaseBreadcrumbs from '@/components/ui/BaseBreadcrumbs.vue';
import BaseDataTable from '@/components/ui/BaseDataTable.vue';
import BaseTransactions from '@/components/ui/BaseTransactions.vue';

Vue.component('BaseTabs', BaseTabs);
Vue.component('BaseIcon', BaseIcon);
Vue.component('BaseMenu', BaseMenu);
Vue.component('BaseImage', BaseImage);
Vue.component('BaseModal', BaseModal);
Vue.component('BaseInput', BaseInput);
Vue.component('BaseTextarea', BaseTextarea);
Vue.component('BaseDateInput', BaseDateInput);
Vue.component('BaseButton', BaseButton);
Vue.component('BaseSelect', BaseSelect);
Vue.component('BaseCheckbox', BaseCheckbox);
Vue.component('BaseAsyncList', BaseAsyncList);
Vue.component('BaseDatePicker', BaseDatePicker);
Vue.component('BaseProgressBar', BaseProgressBar);
Vue.component('BaseBreadcrumbs', BaseBreadcrumbs);
Vue.component('BaseDataTable', BaseDataTable);
Vue.component('BaseTransactions', BaseTransactions);
