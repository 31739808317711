import Config from '@/config';
import vuex from '@/store';
import { ErrorData } from '@/store/modules/Global';

import UString from './String';

export default class UDocument {
  /**
   * Change class of body element
   * @param flag State of loading
   */
  static setLoading(flag) {
    document.body.classList[flag ? 'remove' : 'add']('loaded');
  }

  /**
   * Set title of document
   * @param route Vue-router item
   */
  static setDocumentTitle(route) {
    if (!route) return;

    let title = route.meta?.title || route.name;
    if (route.params.id) title = `${title} ${route.params.id}`;

    document.title = `${title} | ${UString.ucfirst(Config.TEXTS.APP_TITLE)}`;
  }

  /**
   * Download file from link
   * @param url Url of file
   * @param name Name of file
   */
  static download(url = '', name?) {
    const defaultName = new Date()
      .toLocaleString()
      .replace(', ', '_');

    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('target', '_blank');
    element.setAttribute('download', name || defaultName);
    element.click();

    setTimeout(element.remove);
  }


  static downloadAsync(url, name, data = {}) {
    fetch(`${Config.URLS.API.CURRENT}${url}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.token}`
      },
      body: JSON.stringify(data)
    })
      .then(response => response.blob()) // Преобразуем ответ в Blob
      .then(blob => {
        // Создаем ссылку на файл
        const url = window.URL.createObjectURL(blob);
        // Создаем тег <a> для скачивания
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // Указываем имя файла для скачивания
        a.download = name;
        // Добавляем тег <a> в документ
        document.body.appendChild(a);
        // Симулируем клик для скачивания
        a.click();
        // Освобождаем URL-объект
        window.URL.revokeObjectURL(url);
        // Удаляем тег <a> из документа
        document.body.removeChild(a);
      })
      .catch(error => console.error('Ошибка скачивания файла:', error));
  }


  /**
   * Open share-modal on phone-browsers
   * @param text
   * @param title
   * @param url
   */
  static share(text = '', title = document.title, url = window.location.href) {
    // @ts-ignore
    if (window.navigator.share) {
      // @ts-ignore
      window.navigator.share({ url, text, title })
        .catch((err) => {
          // eslint-disable-next-line
          console.error('Error sharing:', err);
        });
    }
  }

  /**
   * Open TheErrorModal
   * @param error
   */
  static async throw(error: ErrorData) {
    await vuex.commit('Global/setError', error);
  }
}
