
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseProgressBar extends Vue {
  @Prop(Boolean) animation;
  @Prop({ default: '0' }) value;
  @Prop({ default: '100' }) max;
  @Prop({ type: String, default: '20px' }) height;

  isStart = true;

  getColor(width) {
    if (width > 0 && width <= 25) return 'var(--vd-strong-cyan)';
    if (width > 25 && width <= 50) return 'var(--vd-indigo)';
    if (width > 50 && width <= 75) return 'var(--vd-amber)';
    if (width > 75 && width <= 100) return 'var(--vd-orange)';
    return 'var(--vd-deep-orange)';
  }

  get styles() {
    const width = (100 * +this.value) / +this.max;

    return {
      height: this.height,
      width: width > 100 ? '100%' : `${width}%`,
      backgroundColor: this.getColor(width),
    };
  }

  mounted() {
    setTimeout(() => { this.isStart = false; }, 300);
  }
}
