import { Module } from 'vuex-module-decorators';
import BaseEntity from '@/store/modules/entities/_BaseEntity';

@Module({ namespaced: true })
export default class Subscribers extends BaseEntity {
  // -------------------------------- //
  // ------------ Getters ----------- //
  // -------------------------------- //

  get $endpoints() {
    return {
      load: 'subscribers',
      loadFields: 'subscriber/fields',
      create: 'subscriber/new',
      delete: 'subscriber',
      edit: 'subscriber',
    };
  }

  get assignedList() {
    return super.assignedList;
  }

  get pagesCount() {
    return super.pagesCount;
  }

  // -------------------------------- //
  // ------------ Actions ----------- //
  // -------------------------------- //

  // ...

  // ----------------------------------- //
  // ------------ Mutations ------------ //
  // ----------------------------------- //

  // ...
}
