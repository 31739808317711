
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import Utils from '@/helpers/utils';

@Component
export default class BaseImage extends Vue {
  @Prop(String) width;
  @Prop(String) height;
  @Prop(String) classes;
  @Prop({ required: true }) src;
  @Prop({ type: String, default: 'Image' }) alt;

  @Ref() image: HTMLImageElement;

  isLoaded = false;
  isLoadedFailed = false;

  get figureClasses() {
    return {
      _loaded: this.isLoaded,
      '_loaded-failed': this.isLoadedFailed,
      'v-base-elevation-2': !this.isLoaded,
    };
  }

  get localStyles() {
    const styles = {} as any;

    // Sizes
    if (this.width) styles.width = Utils.String.cssPropFit(this.width);
    if (this.width) styles.minWidth = Utils.String.cssPropFit(this.width);
    if (this.height) styles.height = Utils.String.cssPropFit(this.height);

    return styles;
  }

  @Watch('src') onSrcChange() {
    if (!this.src) this.onError();
  }

  mounted() {
    this.image.addEventListener('load', this.onLoad);
    this.image.addEventListener('error', this.onError);
    this.onSrcChange();
  }

  beforeDestroy() {
    this.image.removeEventListener('load', this.onLoad);
    this.image.removeEventListener('error', this.onError);
  }

  onLoad() {
    this.isLoaded = true;
    this.isLoadedFailed = false;
  }

  onError() {
    this.isLoaded = false;
    this.isLoadedFailed = true;
  }
}
